import { QUERY_STATUS } from "#constants";
import useCarbonFacilities from "#hooks/useCarbonFacilities";
import useEquipmentTypes from "#hooks/useEquipmentTypes";
import config from "#src/config";
import useLocalization from "#src/hooks/useLocalization";
import { getTimeStringFromDate } from "#src/utils/timeFormatter";
import { Button, useAlert, useForm } from "@validereinc/common-components";
import { EquipmentDomainV2, FacilityDomain } from "@validereinc/domain";
import {
  parseAsLatitudeDegree,
  parseAsLongitudeDegree,
} from "@validereinc/utilities";
import startCase from "lodash/startCase";
import React from "react";

const today = new Date();
const defaultDefaultValues = { effective_date: today };

export function useEquipmentForm({
  isLoading,
  formType,
  onSubmit,
  onCancel,
  defaultValues: defaultValuesProp = {},
}: {
  isLoading?: boolean;
  formType: string;
  onSubmit: (results?: any) => void;
  onCancel: () => void;
  defaultValues?: any;
}) {
  const { addAlert } = useAlert();
  const { localize } = useLocalization();

  const [facilities, facilitiesLoadingState] = useCarbonFacilities();
  const [equipmentTypes, equipmentTypesLoadingState] = useEquipmentTypes();

  const formState =
    isLoading ||
    facilitiesLoadingState !== "loaded" ||
    equipmentTypesLoadingState !== QUERY_STATUS.SUCCESS
      ? "loading"
      : "loaded";

  const defaultValues = {
    ...defaultDefaultValues,
    ...defaultValuesProp,
  };

  const form = useForm({ defaultValues });

  const geolocationInputs = form.watch("attribute");

  const handleSubmitClick = ({ ...values }) => {
    const { type, effective_date, ...cleanValues } = values as Parameters<
      typeof EquipmentDomainV2.createOne
    >[0]["data"];

    const valuesWithLocation = {
      ...cleanValues,
      type_id: type.id,
      effective_date: getTimeStringFromDate(effective_date, config.DATE_FORMAT),
      latitude: parseAsLatitudeDegree(
        String(geolocationInputs?.coordinates?.[0])
      ),
      longitude: parseAsLongitudeDegree(
        String(geolocationInputs?.coordinates?.[1])
      ),
    };

    Promise.all([
      EquipmentDomainV2.createOne({
        data: valuesWithLocation,
      }),
      FacilityDomain.getOne({ id: valuesWithLocation?.facility_id }),
    ])
      .then(([_, relatedFacility]) => {
        addAlert({
          variant: "success",
          message: `Successfully added ${values.name} to ${
            relatedFacility?.data?.name || "facility"
          }`,
        });

        onSubmit?.(values);
      })
      .catch(() => {
        addAlert({
          variant: "error",
          message: `Failed to create equipment`,
        });
      });
  };

  const formProps = {
    handleSubmitClick,
    equipmentTypes,
    equipmentTypesLoadingState,
    facilitiesLoadingState,
    facilities,
    formState,
    form,
  };

  const footer = (
    <>
      <Button
        onClick={onCancel}
        disabled={form.formState.isSubmitting}
      >
        Cancel
      </Button>

      <Button
        variant="primary"
        type="submit"
        onClick={form.handleSubmit((values) => handleSubmitClick(values))}
        disabled={formState !== "loaded" || form.formState.isSubmitting}
      >
        {formType === "add" || formType === "create"
          ? `${startCase(formType)} ${localize("Equipment")}`
          : "Save"}
      </Button>
    </>
  );

  return {
    formProps,
    footer,
  };
}
