import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useListCalculators } from "#hooks/adapters/useCalculators";
import {
  useBulkApplyConfiguration,
  useBulkApplyConfigurationAsync,
  useBulkRunEstimationMethods,
  useBulkRunEstimationMethodsAsync,
  useListEstimationMethodConfiguration,
} from "#hooks/adapters/useEstimationMethods";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  CALCULATIONS_FILTER_CONFIG,
  ImportDataAction,
} from "#src/batteries-included-components";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useReportingGroupMeasurementTypes } from "#src/hooks/useReportingGroupMeasurementTypes";
import {
  SIMPLE_CALCULATOR_STATUS,
  SIMPLE_CALCULATOR_STATUS_OPTIONS,
  rawToSimpleStatus,
  simpleToRawStatus,
} from "#utils/calculatorStatus";
import { getIntervalInMonthsFormatted } from "#utils/date";
import {
  linkToCalculationDetailPage,
  linkToCalculationResultDetailPage,
} from "#utils/links";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  FilterPillVariants,
  FilterPills,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  CalculatorVersionIncludeFields,
  EstimationMethodDomain,
  EstimationMethodWithConfigurationAndRunType,
  MeasurementTypeType,
  SortDirection,
} from "@validereinc/domain";
import {
  downloadLink,
  monthFormatter,
  toFlattenedObject,
  yearMonthParser,
} from "@validereinc/utilities";
import startCase from "lodash/startCase";
import React, { useEffect, useMemo, useState } from "react";
import {
  ASYNC_THRESHOLD,
  getFacilityKey,
  toApiKey,
  toTableKey,
  useAssetSpecificCalculationHeaders,
  useCalculationInputHeaders,
  useCalculationOutputHeaders,
  withTableKey,
} from "./CalculationsTablePanel.helper";

const DEFAULT_FILTER_KEY = "all";

const sorting: SortingType = {
  sortBy: "year_month",
  sortDirection: SortDirection.DESCENDING,
};

export const CalculationsTablePanel = ({
  assetType,
  filterConfigStorageKey,
  tableConfigStorageKey,
  calculationJobStorageKey,
  configurationJobStorageKey,
  defaultMeasurementTypes = [],
  measurementTypeFilter = () => true,
}: {
  assetType: AssetTypeType;
  calculationJobStorageKey: string;
  configurationJobStorageKey: string;
  defaultMeasurementTypes?: string[];
  measurementTypeFilter: (measurementType: MeasurementTypeType) => boolean;
} & StorageKeys) => {
  const { measurementTypes } = useMeasurementTypes();
  const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_KEY);
  const [selected, onSelectionChange] = useState<
    Record<string, EstimationMethodWithConfigurationAndRunType>
  >({});
  const [showBulkRunWarning, setShowBulkRunWarning] = useState(false);
  const [showBulkConfigureWarning, setShowBulkConfigureWarning] =
    useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  const [filters] = useFilters(filterConfigStorageKey);
  const {
    [CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name]:
      equipmentFacilityStatus,
    [CALCULATIONS_FILTER_CONFIG.equipmentStatus.name]: equipmentStatus,
    [CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name]: flowFacilityStatus,
    [CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name]: flowEquipmentStatus,
    [CALCULATIONS_FILTER_CONFIG.flowStatus.name]: flowStatus,
    [CALCULATIONS_FILTER_CONFIG.networkStatus.name]: networkStatus,
  } = toFlattenedObject(filters);

  const {
    [CALCULATIONS_FILTER_CONFIG.reportingScenario.name]: reportingGroup,
    [CALCULATIONS_FILTER_CONFIG.facility.name]: facilityId,
    [CALCULATIONS_FILTER_CONFIG.dateRange.name]: dateRange,
    ...restFilters
  } = filters;

  const formatStatusFilter = (status: string | string[]) =>
    Array.isArray(status) ? status : { $exact: status };

  const { measurementTypeList } = useReportingGroupMeasurementTypes(
    reportingGroup,
    defaultMeasurementTypes
  );

  const measurementKeys = [
    ...defaultMeasurementTypes,
    ...measurementTypeList.filter((key) => {
      const measurementType = measurementTypes.find(({ id }) => id === key);
      return (
        measurementType &&
        measurementTypeFilter(measurementType) &&
        !defaultMeasurementTypes.includes(key)
      );
    }),
  ];

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const calculatorQuery = useListCalculators({
    include: [CalculatorVersionIncludeFields.calculation_parameters],
  });
  const calculatorLookup = useMemo(
    () =>
      Object.fromEntries(
        calculatorQuery.data?.calculators?.map(
          ({ id, default_version, versions }) => [
            id,
            versions.find(({ version }) => version === default_version),
          ]
        ) ?? []
      ),
    [calculatorQuery.data]
  );

  /** We shouldn't need to apply defaults here, but sometimes a race condition overwrites the default date */
  const year_month = getIntervalInMonthsFormatted(
    !!dateRange?.from && !!dateRange?.to
      ? {
          from: new Date(dateRange?.from),
          to: new Date(dateRange?.to),
        }
      : DEFAULT_DATE_RANGES.currentWholeMonth
  );

  const calculationsParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...toFlattenedObject(restFilters),
      ...(facilityId ? { [getFacilityKey(assetType)]: facilityId } : {}),
      status: simpleToRawStatus(statusFilter),
      ...(equipmentFacilityStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name]:
              formatStatusFilter(equipmentFacilityStatus),
          }
        : {}),
      ...(equipmentStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.equipmentStatus.name]:
              formatStatusFilter(equipmentStatus),
          }
        : {}),
      ...(flowFacilityStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name]:
              formatStatusFilter(flowFacilityStatus),
          }
        : {}),
      ...(flowEquipmentStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name]:
              formatStatusFilter(flowEquipmentStatus),
          }
        : {}),
      ...(flowStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.flowStatus.name]:
              formatStatusFilter(flowStatus),
          }
        : {}),
      ...(networkStatus
        ? {
            [CALCULATIONS_FILTER_CONFIG.networkStatus.name]:
              formatStatusFilter(networkStatus),
          }
        : {}),
      entity_type: { $exact: assetType },
      reporting_group_id: { $exact: reportingGroup },
      year_month,
    },
  };

  // These should effectively match calculationsParams.filters
  const bulkRunFilters = Object.keys(selected).length
    ? { $or: Object.values(selected).map(toApiKey) }
    : {
        ...calculationsParams.filters,
        // reporting_group_id -> estimation_method.reporting_group_id
        "estimation_method.reporting_group_id":
          calculationsParams.filters.reporting_group_id,
        reporting_group_id: undefined,
        // analytics_calculator_id -> estimation_method.analytics_calculator_id
        "estimation_method.analytics_calculator_id":
          calculationsParams.filters.analytics_calculator_id,
        analytics_calculator_id: undefined,
        // id -> estimation_method_id
        estimation_method_id: calculationsParams.filters.id,
        id: undefined,
      };

  // Year month is separated out for the async bulk configure
  const bulkConfigureFilters = {
    filters: { ...calculationsParams.filters, year_month: undefined },
    year_months: year_month,
  };

  const calculationsQuery =
    useListEstimationMethodConfiguration(calculationsParams);

  const items =
    calculationsQuery.data?.data.map(withTableKey) ??
    ([] as EstimationMethodWithConfigurationAndRunType[]);

  const totalCount = calculationsQuery.data?.total_entries;
  const calculationCount = Object.keys(selected).length || totalCount || 0;

  /** Reset the selection when the filtered set changes */
  useEffect(() => {
    onSelectionChange({});
  }, [totalCount]);

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { name, s3_download_link } =
        await EstimationMethodDomain.configuration.exportList({
          ...calculationsParams,
        });
      downloadLink(s3_download_link, name);
    } catch (err) {
      console.error(err);
    } finally {
      setIsExporting(false);
    }
  };

  const assetSpecificHeaders = useAssetSpecificCalculationHeaders(
    assetType,
    items
  );

  const calculatorInputHeaders = useCalculationInputHeaders(
    calculatorLookup[filters[CALCULATIONS_FILTER_CONFIG.calculatorName.name]]
      ?.calculation_parameters
  );

  const shouldShowInputs =
    !!filters[CALCULATIONS_FILTER_CONFIG.calculatorName.name];

  const volumeAllowed = ["volume"];
  const networkAllowed = [
    "downstream_volume_adjustable",
    "downstream_volume_non_adjustable",
    "downstream_volume_adjusted",
    "upstream_volume",
    "adjustment_factor",
  ];
  const emissionsAllowed = ["mass_n2o", "mass_co2", "mass_ch4", "mass_co2eq"];

  const calculationOutputHeaders: Array<
    HeaderType<EstimationMethodWithConfigurationAndRunType>
  > = useCalculationOutputHeaders({
    measurementKeys,
    volumeAllowed,
    networkAllowed,
    emissionsAllowed,
    assetType,
  });

  const headers: Array<
    HeaderType<EstimationMethodWithConfigurationAndRunType>
  > = [
    {
      key: "calculator_configuration.year_month",
      label: "Period",
      isSortable: true,
      renderComponent: ({ item }) => {
        const yearMonth = item?.calculator_configuration?.year_month;
        const pathname = linkToCalculationDetailPage(
          assetType,
          item.entity_id,
          item.id
        );
        return (
          <RoutingLink to={pathname}>
            {yearMonth && monthFormatter(yearMonthParser(yearMonth))}
          </RoutingLink>
        );
      },
    },
    ...assetSpecificHeaders.headers,
    ...(assetType !== AssetType.ASSET_GROUP
      ? [
          {
            key: "estimation_method_details",
            label: "Estimation Method Details",
            headers: [
              {
                key: "name",
                label: "Estimation Method",
                isSortable: true,
                minWidth: 180,
                renderComponent: ({
                  item,
                }: {
                  item: EstimationMethodWithConfigurationAndRunType;
                }) => {
                  const pathname = linkToCalculationDetailPage(
                    assetType,
                    item.entity_id,
                    item.id
                  );
                  return <RoutingLink to={pathname}>{item.name}</RoutingLink>;
                },
              },
              {
                key: "analytics_calculator_id",
                label: "Calculator Name",
                isSortable: true,
                minWidth: 180,
                renderComponent: ({
                  item,
                }: {
                  item: EstimationMethodWithConfigurationAndRunType;
                }) =>
                  calculatorLookup[item?.analytics_calculator_id]?.title ??
                  startCase(item?.analytics_calculator_id) ??
                  "-",
              },
            ],
          },
        ]
      : []),
    {
      key: "status",
      label: "Status",
      isSortable: true,
      renderComponent: ({
        item,
      }: {
        item: EstimationMethodWithConfigurationAndRunType;
      }) => {
        const status = rawToSimpleStatus(item.status);
        return (
          <DataTable.DataRow.PillCell
            variant={status?.variant}
            value={status?.name}
          />
        );
      },
    },
    ...(shouldShowInputs ? calculatorInputHeaders : []),
    ...(assetType !== AssetType.ASSET_GROUP
      ? [
          {
            key: "result",
            label: "Result",
            renderComponent: ({
              item,
            }: {
              item: EstimationMethodWithConfigurationAndRunType;
            }) => {
              if (!item.calculator_run?.run_id) {
                return "-";
              }
              const yearMonth = item?.calculator_configuration?.year_month;
              const pathname = linkToCalculationResultDetailPage(
                assetType,
                item.entity_id,
                item.id,
                yearMonth
              );
              return (
                <RoutingLink to={pathname}>
                  {yearMonth && monthFormatter(yearMonthParser(yearMonth))}
                </RoutingLink>
              );
            },
          },
        ]
      : []),
    ...calculationOutputHeaders,
  ];

  const isLoading =
    assetSpecificHeaders.isLoading ||
    calculatorQuery.isLoading ||
    calculationsQuery.isFetching;

  const applyConfigurations = useBulkApplyConfiguration();
  const applyConfigurationAsync = useBulkApplyConfigurationAsync(
    configurationJobStorageKey
  );

  const onClickApplyConfiguration = () => {
    const hasSelection = Object.keys(selected).length > 0;
    const configurationCount = Object.keys(selected).length || totalCount || 0;

    if (hasSelection) {
      // We can't send selected rows through the async API, so we have to use the synchronous call
      applyConfigurations.mutate(Object.values(selected).map(toApiKey));
    } else if (configurationCount > ASYNC_THRESHOLD) {
      setShowBulkConfigureWarning(true);
    } else {
      // We can't apply filters to the synchronous bulk API, so we use async for small numbers of filtered calculators
      applyConfigurationAsync.mutate(bulkConfigureFilters);
    }
  };

  const bulkRun = useBulkRunEstimationMethods(
    assetType !== AssetType.EQUIPMENT
  );
  const bulkRunAsync = useBulkRunEstimationMethodsAsync(
    calculationJobStorageKey,
    assetType !== AssetType.EQUIPMENT
  );

  const onClickCalculate = () => {
    const calculationSize = Object.keys(selected).length || totalCount || 0;
    if (calculationSize > ASYNC_THRESHOLD) {
      setShowBulkRunWarning(true);
    } else {
      bulkRun.mutate(bulkRunFilters);
    }
  };

  const getStatusDetailFilterPillVariant = (status: string) => {
    switch (status) {
      case SIMPLE_CALCULATOR_STATUS.simple_completed.name:
        return FilterPillVariants.GOOD;
      case SIMPLE_CALCULATOR_STATUS.simple_pending.name:
        return FilterPillVariants.NEUTRAL;
      case SIMPLE_CALCULATOR_STATUS.simple_missing.name:
        return FilterPillVariants.ATTENTION;
      default:
        return undefined;
    }
  };

  const filterPills = [
    {
      name: "All",
      label: "All",
      value: null,
      isSelected: true,
    },
    ...SIMPLE_CALCULATOR_STATUS_OPTIONS.map((status) => ({
      name: status.name,
      label: status.name,
      value: status.id,
      variant: getStatusDetailFilterPillVariant(status.name),
      isSelected: statusFilter == status.id,
    })),
  ];

  const actionRow = [
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-calculations"
        resource={{
          id: "estimation_method_config",
          name: "Calculations",
          singular:
            assetType === AssetType.EQUIPMENT
              ? "Emissions Calculation"
              : assetType === AssetType.FLOW
                ? "Volumetric Calculation"
                : "Network Calculation",
          plural:
            assetType === AssetType.EQUIPMENT
              ? "Emissions Calculations"
              : assetType === AssetType.FLOW
                ? "Volumetric Calculations"
                : "Network Calculations",
        }}
      />
    ) : null,
    <Button
      key="export-calculations-records"
      variant="outline"
      onClick={handleExport}
      isLoading={isExporting}
    >
      Export
    </Button>,
    <Button
      key="apply_configurations"
      onClick={onClickApplyConfiguration}
      disabled={!totalCount}
      isLoading={
        applyConfigurations.isLoading || applyConfigurationAsync.isLoading
      }
    >
      Apply Configuration
    </Button>,
    <Button
      key="calculate"
      onClick={onClickCalculate}
      disabled={!totalCount}
      isLoading={bulkRun.isLoading || bulkRunAsync.isLoading}
    >
      Calculate
    </Button>,
  ];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          actionRow,
          title: "Calculations",
          titleDecorator: (
            <FilterPills
              name="status"
              pills={filterPills}
              onChange={setStatusFilter}
            />
          ),
        }}
        dataTableProps={{
          headers,
          items,
          isLoading,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: totalCount ?? 0,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          selected,
          onSelectionChange,
          getItemId: toTableKey,
          getItemActions: ({ item }) => [
            {
              label: "Calculate",
              buttonProps: {
                onClick: () => bulkRun.mutate(toApiKey(item)),
                icon: "play-circle",
              },
            },
          ],
        }}
      />
      <Dialog
        key="initiate-large-bulk-run-dialog"
        title="Initiate Large Bulk Run?"
        isOpen={showBulkRunWarning}
        onClose={() => setShowBulkRunWarning(false)}
        actionRow={[
          <Button
            key="bulk-run-action"
            variant="primary"
            onClick={() => {
              bulkRunAsync.mutate(bulkRunFilters);
              setShowBulkRunWarning(false);
            }}
          >
            Run Calculations
          </Button>,
        ]}
      >
        You have selected to run all {calculationCount}{" "}
        {Object.keys(selected).length ? "selected" : "filtered"} calculations.
        This may take some time.
      </Dialog>
      <Dialog
        key="initiate-large-bulk-configure-dialog"
        title="Initiate Large Bulk Configuration?"
        isOpen={showBulkConfigureWarning}
        onClose={() => setShowBulkConfigureWarning(false)}
        actionRow={[
          <Button
            key="bulk-configure-action"
            variant="primary"
            onClick={() => {
              applyConfigurationAsync.mutate(bulkConfigureFilters);
              setShowBulkConfigureWarning(false);
            }}
          >
            Apply Configurations
          </Button>,
        ]}
      >
        You have selected to apply configurations to all {calculationCount}{" "}
        {Object.keys(selected).length ? "selected" : "filtered"} calculations.
        This may take some time.
      </Dialog>
    </>
  );
};
