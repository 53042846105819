import {
  AssetTypeType,
  Device,
  EquipmentDomainV2,
  FacilityDomain,
  FlowDomain,
  FormSchemaDataType,
  FormSchemaSectionType,
  FormSubmissionAnswerType,
  FormSubmissionType,
  GetOneResponseType,
  LookupFieldType,
  Resources,
  ResourceServiceType,
  ResourceType,
} from "@validereinc/domain";
import isObject from "lodash/isObject";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import { UseFormReturn, usePrevious } from "@validereinc/common-components";
import { useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { areConditionsSatisfied as areConditionsSatisfiedShared } from "@validereinc/domain-controllers/logic/forms";

export const $in = ({
  value,
  comparator,
}: {
  value: string | number;
  comparator: Array<string | number>;
}) => comparator.includes(value);

export const $eq = ({
  value,
  comparator,
}: {
  value: boolean | string | number | Date;
  comparator: boolean | string | number | Date;
}) => value === comparator;

export const $lt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value < comparator;

export const $lte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value <= comparator;

export const $gt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value > comparator;

export const $gte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value >= comparator;

const conditionFunctions = {
  $in,
  $eq,
  $lt,
  $lte,
  $gt,
  $gte,
};

export const areConditionsSatisfied = ({
  conditions,
  values,
  fieldName,
}: {
  conditions:
    | Record<
        string,
        {
          [key in keyof typeof conditionFunctions]: boolean | number | string;
        }
      >
    | string;
  values: Record<string, string | number | boolean | Date>;
  fieldName: string;
}) => {
  if (!conditions) {
    return true;
  }

  let formattedConditions;

  if (typeof conditions === "string") {
    formattedConditions = JSON.parse(conditions);
  } else {
    formattedConditions = conditions;
  }

  return Object.entries(formattedConditions).every(([key, value]) => {
    const parentFieldName = getParentQuestionName({
      currentQuestionId: fieldName,
      formValues: values,
      questionIdToFind: key,
    });

    return !isPlainObject(value)
      ? $eq({ value, comparator: get(values, parentFieldName) })
      : Object.entries(value).every(([condition, comparator]) => {
          return conditionFunctions?.[condition]({
            value: get(values, parentFieldName),
            comparator,
          });
        });
  });
};

export const getParentQuestionName = ({
  currentQuestionId,
  formValues,
  questionIdToFind,
}: {
  currentQuestionId: string;
  formValues: Record<string, unknown>;
  questionIdToFind: string;
}) => {
  if (currentQuestionId && questionIdToFind && formValues) {
    const splitName = currentQuestionId?.split(".");

    const nameStart = `answers.${splitName[1]}`;

    const value = get(
      formValues,
      `${nameStart}.${splitName[2]}.${questionIdToFind}`
    );

    if (value) {
      return `${nameStart}.${splitName[2]}.${questionIdToFind}.value`;
    }

    const fieldName = Object.entries(formValues?.answers ?? {}).find(
      ([key, objectValue]) =>
        key !== splitName[1] && objectValue?.[0]?.[questionIdToFind]
    )?.[0];

    return `answers.${fieldName}.0.${questionIdToFind}.value`;
  }
};

export const getSmartDefaultValues = (
  formSchema: FormSchemaDataType | undefined,
  contextForDefaults: {
    now?: string;
    currentUserName?: string;
    associatedAssetId?: string;
    associatedAssetType?:
      | "facility"
      | "equipment"
      | "device"
      | "flow"
      | "asset_group";
    defaultValues?: Record<string, string>;
  } = { defaultValues: {} }
) => {
  if (!formSchema) return {};

  return {
    answers: formSchema?.config?.sections.reduce(
      (
        total: Record<string, Array<Record<string, FormSubmissionAnswerType>>>,
        { id, questions }: FormSchemaSectionType
      ) => ({
        ...total,
        [id]: [
          questions.reduce<Record<string, FormSubmissionAnswerType>>(
            (questionDefaults, qid) => {
              const question = formSchema?.config?.questions[qid];

              if (!question) return questionDefaults;

              let defaultAnswer =
                contextForDefaults?.defaultValues?.[
                  `$.questions.${qid}.default_answer`
                ] ??
                question?.default_answer ??
                "";

              /*
              If the default answer type is an object (in this case, CHB-3964),
              don't use it as a default value at all. Otherwise it will render
              as [object Object].
              */
              if (
                isObject(defaultAnswer) &&
                "lookup_attribute" in defaultAnswer &&
                "lookup_question_id" in defaultAnswer
              ) {
                defaultAnswer = "";
              }

              questionDefaults[qid] = {
                value: defaultAnswer,
              };

              switch (question.type) {
                case "question":
                  switch (question.data_type) {
                    case "date-time":
                    case "date": {
                      if (
                        questionDefaults[qid]?.value === "$now" &&
                        contextForDefaults.now
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.now,
                        };
                      break;
                    }
                    case "string": {
                      if (
                        questionDefaults[qid]?.value === "$user_name" &&
                        contextForDefaults.currentUserName
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.currentUserName,
                        };
                      break;
                    }
                    case "lookup": {
                      if (
                        !contextForDefaults.associatedAssetId ||
                        !contextForDefaults.associatedAssetType ||
                        contextForDefaults.associatedAssetType !==
                          question.lookup_entity_type
                      )
                        break;

                      questionDefaults[qid] = {
                        value: contextForDefaults.associatedAssetId,
                      };
                    }
                  }
              }
              return questionDefaults;
            },
            {}
          ),
        ],
      }),
      {}
    ),
  };
};

/**
  This hook returns a key-value pair where:
  Key is the full name of the lookup-type form field ("answers.sectionName.sectionIndex.questionId") that its value change, needs to be watched (let's call it "LUF")
  Value has the following structure:
    {
      fieldsToOverride: { lookupAttribute: string; fieldName: string; }[];
      entityType: AssetTypeType;
    }

  where "entityType" is the lookup entity type of LUF (LUF.lookup_entity_type where it can be facility, equipment, etc.)
  "fieldsToOverride" is an array of all the question fields that are affected when value of LUF changes.
  Where value of "fieldName" (full name of form field) should be set to LUF.custom_attributes."lookupAttribute" resolved value.

  Example:

  {
    "answers.section1.0.facilityQuestion": {
      fieldsToOverride: [
        {lookupAttribute: 'country', fieldName: 'answers.section1.0.facilityCountry'},
        {lookupAttribute: 'is_facility_clean', fieldName: 'answers.section1.0.facilityCleannessBoolean'},
      ],
      entityType: 'facility'
    },
    ...
  }

  When user changes the value of facilityQuestion, a facility is fetched with facilityQuestion's value (let's call it fetchedFacility) and:
    - value of "facilityCountry" is set fetchedFacility.custom_attributes.country
    - value of "facilityCleannessBoolean" is set fetchedFacility.custom_attributes.is_facility_clean

 */
export const useGenerateFieldsToWatch = ({
  form,
  formSchema,
  formValues,
}: {
  form: UseFormReturn<any>;
  formSchema?: FormSchemaDataType;
  formValues: Pick<FormSubmissionType, "answers">;
}) =>
  useMemo(() => {
    const fieldsToWatch: Record<
      string,
      {
        fieldsToOverride: Array<{
          lookupAttribute: string;
          fieldName: string;
        }>;
        entityType: AssetTypeType;
      }
    > = {};

    formSchema?.config.sections.forEach((section) => {
      if (isEmpty(formValues) || isEmpty(formValues?.answers)) return;
      section.questions.forEach((questionId) => {
        const question = formSchema.config.questions[questionId];
        const defaultAnswer =
          (question?.default_answer as string | Record<string, string>) ?? "";
        if (isObject(defaultAnswer)) {
          if (
            "lookup_attribute" in defaultAnswer &&
            "lookup_question_id" in defaultAnswer
          ) {
            for (
              let sectionIndex = 0;
              sectionIndex < formValues.answers[section.id].length;
              sectionIndex++
            ) {
              let listenTo = "";
              if (section.questions.includes(defaultAnswer.lookup_question_id))
                listenTo = `answers.${section.id}.${sectionIndex}.${defaultAnswer.lookup_question_id}`;
              else {
                const otherSection = formSchema?.config.sections
                  .filter((otherSection) => otherSection.id !== section.id)
                  .find((searchSection) =>
                    searchSection.questions.includes(
                      defaultAnswer.lookup_question_id
                    )
                  );
                if (otherSection)
                  listenTo = `answers.${otherSection.id}.0.${defaultAnswer.lookup_question_id}`;
              }

              const fieldToOverride = {
                lookupAttribute: defaultAnswer.lookup_attribute,
                fieldName: `answers.${section.id}.${sectionIndex}.${questionId}`,
              };

              if (fieldsToWatch[listenTo]) {
                fieldsToWatch[listenTo].fieldsToOverride.push(fieldToOverride);
              } else {
                const entityType =
                  (
                    formSchema.config.questions[
                      defaultAnswer.lookup_question_id
                    ] as LookupFieldType
                  )?.lookup_entity_type ?? "";
                fieldsToWatch[listenTo] = {
                  entityType,
                  fieldsToOverride: [fieldToOverride],
                };
              }
            }
          }
        }
      });
    });

    return fieldsToWatch;
  }, [form, formSchema, JSON.stringify(formValues)]);

const resourceToDomainGetOneMap: Partial<
  Record<ResourceType, ResourceServiceType<any>["getOne"]>
> = {
  [Resources.FACILITY]: FacilityDomain.getOne,
  [Resources.EQUIPMENT]: EquipmentDomainV2.getOne,
  [Resources.DEVICE]: Device.getOne,
  [Resources.FLOW]: FlowDomain.getOne,
};

/**
 *
 * returns an array of objects in the form of:
 * { queryProps, meta }[] where queryProps are passed to useQueries,
 * and meta that contains information about what fields values should be overridden
 */
export const useGenerateLookupQueries = ({
  fieldsToWatch,
  formValues,
  dirtyFields,
}: {
  formValues: Pick<FormSubmissionType, "answers">;
  fieldsToWatch: Record<
    string,
    {
      fieldsToOverride: Array<{
        lookupAttribute: string;
        fieldName: string;
      }>;
      entityType: AssetTypeType;
    }
  >;
  dirtyFields: object;
}) => {
  /*
  Only create a query for the fields that are touched.
  This helps with editing a form submission with lookup attribute values with a custom value.
  */
  const [wasTouched, setWasTouched] = useState<Record<string, boolean>>({});

  useEffect(() => {
    Object.keys(fieldsToWatch).forEach((field) => {
      if (get(dirtyFields, `${field}.value`))
        setWasTouched((prev) => ({ ...prev, [field]: true }));
    });
  }, [JSON.stringify(formValues)]);

  return useMemo(
    () =>
      Object.entries(fieldsToWatch)
        .map(([fieldToWatch, fieldWatchConfiguration]) => {
          // Don't create a query for a field that wasn't even touched!
          if (!wasTouched?.[fieldToWatch]) return;
          const entityIdToFetch = get(formValues, `${fieldToWatch}.value`);
          if (!entityIdToFetch) return;
          const getOne =
            resourceToDomainGetOneMap?.[fieldWatchConfiguration.entityType];
          if (!getOne) return;

          return {
            queryProps: {
              queryKey: [
                fieldWatchConfiguration.entityType,
                "getOne",
                { id: entityIdToFetch },
              ],
              queryFn: () =>
                getOne({
                  id: entityIdToFetch,
                }),
            },
            meta: {
              fieldWatchConfiguration,
              fieldToWatch,
            },
          };
        })
        // Both of these filters are required for having absolutely zero type warnings and errors:
        .filter((q) => !!q)
        .filter((q) => !!q.queryProps),
    [
      Object.keys(wasTouched).length,
      JSON.stringify(fieldsToWatch),
      ...Object.keys(fieldsToWatch).map((field) =>
        get(formValues, `${field}.value`)
      ),
    ]
  );
};

export const useProcessAttributeLookupQueries = ({
  queries,
  queriesDetails,
  fieldsToWatch,
  form,
  formValues,
  formSchema,
}: {
  queries: Array<UseQueryResult<GetOneResponseType<any> | undefined>>;
  queriesDetails: ReturnType<typeof useGenerateLookupQueries>;
  form: UseFormReturn<any>;
  formValues: Pick<FormSubmissionType, "answers">;
  formSchema?: FormSchemaDataType;
  fieldsToWatch: Record<
    string,
    {
      fieldsToOverride: Array<{
        lookupAttribute: string;
        fieldName: string;
      }>;
      entityType: AssetTypeType;
    }
  >;
}) => {
  const { resolved, fieldsNamesToDisable } = useMemo(() => {
    const fieldsNamesToDisable = new Set();
    const output: Record<string, Record<string, any>> = {};

    queries.forEach((query, index) => {
      if (!queriesDetails?.[index]) return;
      const queryDetails = queriesDetails[index].meta;
      if (!output[queryDetails.fieldToWatch])
        output[queryDetails.fieldToWatch] = {};

      queryDetails.fieldWatchConfiguration.fieldsToOverride.forEach((field) => {
        const fieldName = `${field.fieldName}.value`;
        if (query.isFetching) {
          fieldsNamesToDisable.add(fieldName);
        } else {
          fieldsNamesToDisable.delete(fieldName);
        }
      });

      queryDetails.fieldWatchConfiguration.fieldsToOverride.forEach(
        (fieldToOverride) => {
          const resolvedValue =
            query.data?.data?.custom_attributes?.[
              fieldToOverride.lookupAttribute
            ] ?? "";
          output[queryDetails.fieldToWatch][fieldToOverride.fieldName] =
            resolvedValue;
        }
      );
    });
    return { resolved: output, fieldsNamesToDisable };
  }, [queries, queriesDetails]);

  const resolvedSerialized = JSON.stringify(resolved);

  const previousResolvedSerialized = usePrevious(resolvedSerialized);

  useEffect(() => {
    if (!previousResolvedSerialized) return;
    const previousResolved = JSON.parse(previousResolvedSerialized ?? "{}");

    Object.keys(fieldsToWatch).forEach((fieldKey) => {
      if (
        resolved[fieldKey] &&
        get(previousResolved, fieldKey) !== get(resolved, fieldKey)
      ) {
        Object.entries(resolved[fieldKey]).forEach(
          ([fieldToOverride, valueToOverride]) => {
            const questionId = fieldToOverride.split(/[. ]+/).pop() ?? "";
            // Only set the value for the question if it's rendered:
            if (
              areConditionsSatisfiedShared({
                fieldName: `${fieldToOverride}.value`,
                values: formValues,
                conditions:
                  formSchema?.config.questions?.[questionId]?.conditions,
              })
            )
              form.setValue(`${fieldToOverride}.value`, valueToOverride);
          }
        );
      }
    });
  }, [
    resolvedSerialized,
    JSON.stringify(fieldsToWatch),
    JSON.stringify(formSchema),
    form,
  ]);
  return { fieldsNamesToDisable };
};
