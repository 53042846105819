import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { DeleteDraftFormSubmissionDialog } from "#src/batteries-included-components/Dialogs/DeleteDraftFormSubmissionDialog";
import AlertMessageWithLink from "#src/components/Common/Alerts/AlertMessageWithLink";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import {
  useGetOneFormSubmission,
  useUpdateOneFormSubmission,
} from "#src/components/hooks/adapters/useFormSubmissions";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useBlockNavigation } from "#src/hooks/useBlockNavigation";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { ExceptionUtils } from "#src/utils/exception";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Form,
  Page,
  Tooltip,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  BaseError,
  FormCategoryAdapter,
  FormSchema,
  FormSubmission,
  FormSubmissionType,
  UserType,
} from "@validereinc/domain";
import { dateFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useMemo, useState } from "react";
import { UPDATE_FORM_SUBMISSION_BREADCRUMB } from ".";
import { FORM_TEMPLATE_DETAILS_BREADCRUMB, linkToFormTemplateDetail } from "..";
import { FORM_CATEGORY_DETAILS_BREADCRUMB } from "../../..";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../../../components/hooks/adapters/adapterUtils";
import styles from "./UpdateFormSubmissionPage.module.scss";
import { FormSubmissionFormController } from "@validereinc/domain-controllers/view/forms";
import { FormSubmissionSection } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionSection";
import { FormSubmissionField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField";
import {
  useGenerateFieldsToWatch,
  useGenerateLookupQueries,
  useProcessAttributeLookupQueries,
} from "../create-form-submission/util";

const cx = classNames.bind(styles);

export const UpdateFormSubmissionPage = () => {
  const navigate = useNavigate();
  const { categoryId, formTemplateId, formSubmissionId } = useParams<{
    categoryId: string;
    formTemplateId: string;
    formSubmissionId: string;
  }>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelSaveDialogOpen, setIsCancelSaveDialogOpen] = useState(false);
  const { addAlert } = useAlert();

  const formSubmissionQuery = useGetOneFormSubmission({
    id: formSubmissionId,
  });

  const formSubmission = formSubmissionQuery.data?.data;

  const query = useQuery({
    queryKey: ["formSchemas", formTemplateId] as const,
    queryFn: ({ queryKey: [_, formSchemaId] }) =>
      FormSchema.getOne({ id: formSchemaId }),
    ...DEFAULT_QUERY_OPTIONS,
  });

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;

      return await FormCategoryAdapter.getOne({
        id,
      });
    },
  });

  const templateQuery = useQuery({
    queryKey: ["formSchemas", formTemplateId],
    queryFn: async ({ queryKey }) => {
      const [_, formSchemaId] = queryKey;

      return await FormSchema.getOne({
        id: formSchemaId,
      });
    },
    staleTime: 2 * 60 * 1000,
  });

  const metaUsersQueries = useGetManyUsers(
    Array.from(
      new Set(
        [formSubmission?.created_by, formSubmission?.updated_by].filter(
          (id) => !!id
        ) as string[]
      )
    )
  );

  const metaUsersMap = useMemo(() => {
    return (
      metaUsersQueries?.reduce<Record<string, UserType>>((map, query) => {
        if (!query.data) {
          return map;
        }

        map[query.data.id] = query.data;
        return map;
      }, {}) ?? {}
    );
  }, [metaUsersQueries]);

  const form = useForm<Pick<FormSubmissionType, "answers">>({
    defaultValues: {
      // IMPROVE: split into it's own function
      answers: formSubmission?.answers
        ? Object.fromEntries(
            Object.entries(formSubmission?.answers).map(
              ([key, answersArray]) => [
                key,
                answersArray?.length
                  ? answersArray.map((answers) =>
                      Object.fromEntries(
                        Object.entries(answers).map(([key, { value }]) => {
                          const dataType =
                            templateQuery?.data?.config?.questions?.[key]
                              ?.data_type;

                          if (
                            ["date", "date-time", "date-time-range"].includes(
                              dataType
                            ) &&
                            value
                          ) {
                            return [
                              key,
                              {
                                value:
                                  dataType === "date-time-range"
                                    ? value.map((date) => new Date(date))
                                    : new Date(value),
                              },
                            ];
                          } else {
                            return [key, { value }];
                          }
                        })
                      )
                    )
                  : [{}],
              ]
            )
          )
        : {},
    },
  });

  const formValues = form.watch();

  const formSchema = templateQuery.data;

  const fieldsToWatch = useGenerateFieldsToWatch({
    form,
    formSchema,
    formValues,
  });

  const attributeLookupQueryDetails = useGenerateLookupQueries({
    fieldsToWatch,
    formValues,
    dirtyFields: form.formState.dirtyFields,
  });

  const attributeLookupQueries = useQueries({
    queries: attributeLookupQueryDetails.map((q) => q.queryProps),
  });

  const { fieldsNamesToDisable } = useProcessAttributeLookupQueries({
    queries: attributeLookupQueries,
    queriesDetails: attributeLookupQueryDetails,
    fieldsToWatch,
    form,
    formValues,
    formSchema,
  });

  const { isDirty, dirtyFields } = form.formState;
  const queryClient = useQueryClient();
  const [, unblockNavigation] = useBlockNavigation(
    isDirty || !!Object.keys(dirtyFields?.answers ?? {}).length,
    () => setIsCancelSaveDialogOpen(true)
  );

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      FORM_TEMPLATE_DETAILS_BREADCRUMB,
      UPDATE_FORM_SUBMISSION_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
      3: templateQuery?.data?.name,
    }
  );

  const mutation = useUpdateOneFormSubmission({
    onSuccess: () => {
      unblockNavigation();
    },
    successMessage: (data, formValues) =>
      formValues.data.status !== "draft" ? (
        <AlertMessageWithLink
          mainText="Successfully created form submission."
          linkText="View Submission Details"
          position="bottom"
          onLinkClick={() =>
            navigate({
              pathname: linkToFormSubmissionDetail(
                (data as { data: FormSubmissionType }).data.id
              ),
            })
          }
        />
      ) : (
        "Successfully saved form as draft."
      ),
    onError: (err, formValues) => {
      unblockNavigation();
      ExceptionUtils.reportException(err, "error", {
        hint:
          formValues.status !== "draft"
            ? "Failed to create form submission from draft."
            : "Failed to save form draft.",
      });
    },
    errorMessage: (_, formValues) => {
      addAlert?.({
        variant: "error",
        message:
          formValues.status !== "draft"
            ? "Failed to create form submission from draft."
            : "Failed to save form draft.",
      });
    },
  });

  const patchFormValuesForMutation = (
    formValues: Parameters<typeof FormSubmission.updateOne>[0]["data"]
  ) => {
    let status = formValues.status;

    if (!status) {
      status =
        templateQuery?.data?.form_submission_default_status ?? "submitted";
    }
    return {
      ...formValues,
      status,
    };
  };

  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: true,
    showUpdatedAt: true,
    metaUserDataMap: metaUsersMap,
  });

  const onCancel = () => {
    navigate.goBack();
  };
  const onSubmit = form.handleSubmit(
    (formValues) => {
      mutation.mutate(
        {
          id: formSubmissionId,
          data: patchFormValuesForMutation(formValues),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["forms", "submissions"],
            });

            navigate({
              pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
              query: {
                "submission-type": "all",
              },
              replace: true,
            });
          },
        }
      );
    },
    (err) => {
      unblockNavigation();
      addAlert({
        variant: "error",
        message: "Failed to submit form",
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to submit form at the react hook form level",
        sourceComponent: "UpdateFormSubmissionPage.tsx",
      });
    }
  );
  const onSaveAsDraft = () => {
    const formValues = form.getValues();

    unblockNavigation();

    return mutation.mutateAsync(
      {
        id: formSubmissionId,
        data: patchFormValuesForMutation({ ...formValues, status: "draft" }),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["forms", "submissions"],
          });

          navigate({
            pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
            query: {
              "submission-type": "draft",
            },
            replace: true,
          });
        },
      }
    );
  };
  const onDelete = () => {
    unblockNavigation();
    navigate({
      pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
      replace: true,
      query: { "submission-type": "draft" },
    });
  };

  const errorCount = Object.values(
    form.formState?.errors?.answers ?? {}
  ).reduce((total, value) => {
    const newErrors = value.map((value) => Object.keys(value ?? {})).flat();

    return (total += newErrors.length);
  }, 0);

  return (
    <>
      <Page
        isLoading={query?.isLoading || formSubmissionQuery.isLoading}
        category={UPDATE_FORM_SUBMISSION_BREADCRUMB.title}
        title={templateQuery.data?.name}
        renderMeta={({ MetaSegments }) => (
          <MetaSegments
            values={[
              formSubmission?.updated_at
                ? `Last edited ${dateFormatter(
                    new Date(formSubmission.updated_at ?? "")
                  )}${
                    metaUsersMap[formSubmission.updated_by]
                      ? ` by ${metaUsersMap[formSubmission.updated_by].name}`
                      : ""
                  }`
                : "",
              formSubmission?.created_at
                ? `Created ${dateFormatter(
                    new Date(formSubmission.created_at ?? "")
                  )}${
                    metaUsersMap[formSubmission.created_by]
                      ? ` by ${metaUsersMap[formSubmission.created_by].name}`
                      : ""
                  }`
                : "",
            ]}
          />
        )}
        breadcrumbs={breadcrumbs}
        footer={
          <div className={cx("footerContainer")}>
            <Button
              key="cancel-action"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <div className={cx("footerActionsContainer")}>
              {errorCount ? (
                <p style={{ color: "red", margin: 0 }}>
                  {errorCount
                    ? `${errorCount} field${
                        errorCount > 1 ? "s have" : " has"
                      } an error. Please fix the error${
                        errorCount > 1 ? "s" : ""
                      } to submit the form.`
                    : ""}
                </p>
              ) : null}

              <Button
                key="delete-draft-action"
                onClick={() => setIsDeleteDialogOpen(true)}
                isLoading={isDeleteDialogOpen}
                disabled={mutation.isLoading}
                variant="error-outline"
              >
                Delete Draft
              </Button>

              <Button
                key="save-as-draft-action"
                onClick={onSaveAsDraft}
                isLoading={
                  mutation.isLoading &&
                  mutation.variables?.data.status === "draft"
                }
                disabled={mutation.isLoading}
              >
                Save as Draft
              </Button>

              <Tooltip content="Form will be saved as draft before the export generates">
                <Button
                  icon="file-pdf"
                  iconPosition="left"
                  disabled={formSubmissionQuery.isLoading}
                  isLoading={exportPDFMutation.isLoading}
                  onClick={() => {
                    if (!formSubmission) return;

                    const formValues = form.getValues();

                    mutation
                      .mutateAsync({
                        id: formSubmissionId,
                        data: patchFormValuesForMutation({
                          ...formValues,
                          status: "draft",
                        }),
                      })
                      .then(() => formSubmissionQuery.refetch())
                      .then((data) => {
                        if (!data?.data)
                          throw new BaseError(
                            "Failed to refetch draft form submission"
                          );

                        return exportPDFMutation.mutateAsync(data.data.data);
                      })
                      .catch(() => {
                        addAlert({
                          variant: "error",
                          message:
                            "Failed to save draft. Exporting using last saved state.",
                        });
                        exportPDFMutation.mutate(formSubmission);
                      });
                  }}
                >
                  Export PDF
                </Button>
              </Tooltip>

              <Button
                key="submit-action"
                variant="primary"
                onClick={onSubmit}
                isLoading={
                  mutation.isLoading &&
                  mutation.variables?.data.status !== "draft"
                }
                disabled={mutation.isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      >
        <Form {...form}>
          <FormSubmissionFormController
            form={form}
            formSchema={query?.data}
            sectionRenderFunction={(all) => <FormSubmissionSection {...all} />}
            questionRenderFunction={(all) => {
              return (
                <FormSubmissionField
                  {...all}
                  isDisabled={fieldsNamesToDisable.has(all.name)}
                />
              );
            }}
          />
        </Form>
      </Page>

      <DeleteDraftFormSubmissionDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={onDelete}
        formSubmissionId={formSubmissionId}
      />

      <Dialog
        isOpen={isCancelSaveDialogOpen}
        onClose={() => setIsCancelSaveDialogOpen(false)}
        title="Discard changes?"
        actionRow={[
          <Button
            key="yes"
            variant="error"
            onClick={() => {
              unblockNavigation();
              onCancel();
            }}
          >
            Yes
          </Button>,
        ]}
      >
        You&apos;ve made changes to this draft submission. Are you sure you want
        to leave your changes unsaved? They will be discarded.
      </Dialog>
    </>
  );
};
