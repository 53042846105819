import { AssetTypeType, FormSubmissionStatusType } from "../schemas";
import {
  AttributeDataType,
  AttributeDataTypeType,
} from "../schemas/AttributeSchemas";
import type {
  DomainModelMetaExtendedType,
  DomainModelType,
  GetListRequestType,
  GetListResponseType,
} from "../util";
import { restAPI } from "./";

export interface FormSchemaDataType
  extends DomainModelType,
    Omit<DomainModelMetaExtendedType, "updated_by"> {
  name: string;
  form_category_id: string;
  form_submission_default_status: FormSubmissionStatusType | null;
  status: "active" | "archived" | "draft";
  version: number;
  company_id: string;
  config: {
    questions: FormSchemaQuestionType;
    sections: FormSchemaSectionType[];
  };
  description?: string;
  form_category: {
    archived: boolean;
    company_id: string;
    created_at: string;
    id: string;
    name: string;
    updated_at: string;
  };
}

export type GetFormSchemasResponse = GetListResponseType<FormSchemaDataType>;

export type DefaultLookupAnswerType = {
  lookup_question_id: string;
  lookup_attribute: string;
};

export type FormSchemaQuestionCommonFields = {
  prompt: string;
  equation?: string;
  description?: string;
  is_required?: boolean;
  conditions?: object;
  default_answer?: string | number | boolean | object | DefaultLookupAnswerType;
};

export type FormSchemaQuestionMeasurementFields =
  FormSchemaQuestionCommonFields & {
    measurement_type: string;
    measurement_unit: string;
    subject_lookup_question_id: keyof FormSchemaQuestionType;
    source_lookup_question_id: keyof FormSchemaQuestionType;
  };

export type LookupFieldType = FormSchemaQuestionCommonFields & {
  data_type: Extract<AttributeDataTypeType, "lookup">;
  lookup_entity_type: AssetTypeType;
  lookup_entity_attributes?: string[];
};

export type InputFieldType = FormSchemaQuestionCommonFields & {
  data_type:
    | Extract<
        AttributeDataTypeType,
        | "string"
        | "number"
        | "integer"
        | "boolean"
        | "date"
        | "date-time"
        | "pick-list"
        | "multi-pick-list"
        | "geo_point"
        | "file"
      >
    | "date-time-range";
  pick_list_values?: string[];
};

export type FormSchemaQuestionQuestionFields = LookupFieldType | InputFieldType;

export type FormMeasurementType = {
  type: "measurement";
} & FormSchemaQuestionMeasurementFields;

export type FormQuestionType = {
  type: "question";
} & FormSchemaQuestionQuestionFields;

export type FormSchemaQuestionType = Record<
  string,
  FormMeasurementType | FormQuestionType
>;

export type FormSchemaDisplayQuestionType = FormSchemaQuestionType & {
  id: string;
  question_number: number;
};

export const ReorderDirection = { UP: "up", DOWN: "down" } as const;

export type ReorderDirectionType =
  (typeof ReorderDirection)[keyof typeof ReorderDirection];

export interface FormSchemaSectionType extends DomainModelType {
  description?: string;
  name: string;
  questions: Array<keyof FormSchemaQuestionType>;
  is_repeatable: boolean;
}

export type FormSchemaFilterType = {
  form_category_id?: string;
  form_category_name?: string;
  name?: string;
  status?: string;
};

export type FormQuestionLookupFilterType = {
  attribute: string;
  filterType: string;
  staticValue: unknown;
  referenceQuestion?: string;
};

export type FormSchemaUpdateOneType = {
  id: string;
  data: Partial<
    Pick<
      FormSchemaDataType,
      "form_category_id" | "status" | "form_submission_default_status"
    >
  >;
};

export const isMeasurementField = (
  question: FormSchemaQuestionType[string]
): question is FormMeasurementType => question.type === "measurement";

export const isLookupField = (
  question: FormSchemaQuestionQuestionFields
): question is LookupFieldType => {
  return (question as FormQuestionType)?.data_type === AttributeDataType.LOOKUP;
};
export const isStringField = (
  question: FormSchemaQuestionQuestionFields
): question is LookupFieldType => {
  return (question as FormQuestionType)?.data_type === AttributeDataType.STRING;
};

export const FormSchema = {
  getFormSchemas: async ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<FormSchemaFilterType>) => {
    const { name, form_category_id, status } = filters ?? {};

    return await restAPI.nodeAPI.GET<GetFormSchemasResponse>({
      endpoint: "/form_schemas",
      query: {
        ...(name ? { name } : {}),
        ...(form_category_id ? { form_category_id } : {}),
        ...(status && { status }),
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    });
  },
  /**
   * Get a single form schema (form template)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_schemas/get_form_schema
   */
  getOne: async ({ id }: { id: string }) =>
    restAPI.nodeAPI.GET<FormSchemaDataType>({
      endpoint: `/form_schemas/${id}`,
    }),

  getList: ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<FormSchemaFilterType>) =>
    restAPI.nodeAPI.POST<GetFormSchemasResponse>({
      endpoint: "/form_schemas/search",
      body: {
        filter: filters,
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    }),

  createOne: ({ data }: { data: FormSchemaDataType }) =>
    restAPI.nodeAPI.POST<FormSchemaDataType>({
      endpoint: "/form_schemas",
      body: data,
    }),

  updateOne: ({ id, data }: FormSchemaUpdateOneType) =>
    restAPI.nodeAPI.PUT<FormSchemaDataType>({
      endpoint: `/form_schemas/${id}`,
      body: data,
    }),
};
