import {
  StatusVariants,
  SummaryInformation,
  SummaryInformationContainer,
} from "#common/SummaryInformation";
import { getStatusType } from "#common/Table/rendererHelper";
import FileDownloadLink from "#components/Common/FileDownloadLink/FileDownloadLink";
import {
  LegacyBreadcrumbType,
  getBreadcrumbsObject,
} from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToFormSubmissionList } from "#routers/links";
import FormService from "#src/components/Services/FormService";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import config from "#src/config";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToAssetDetailPage } from "#utils/links";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Column,
  Dialog,
  DropdownMenu,
  KeyValuePanel,
  Link,
  Page,
  Panel,
  Row,
  useAlert,
} from "@validereinc/common-components";
import {
  AssetTypeType,
  BaseError,
  FormCategoryAdapter,
  FormSubmissionType,
} from "@validereinc/domain";
import {
  booleanFormatter,
  dateFormatter,
  datetimeFormatter,
  downloadXLSX,
  formatCoordinateDegrees,
} from "@validereinc/utilities";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteOneFormSubmission,
  useGetOneFormSubmission,
  useUpdateOneFormSubmission,
} from "../hooks/adapters/useFormSubmissions";
import UpdateStatusModal from "./UpdateStatusModal";
import { useExportFormSubmissionAsPDF } from "./exportFormSubmission";
import startCase from "lodash/startCase";

const getLinkPath = ({
  value,
  entity_type,
}: {
  value: string;
  entity_type: AssetTypeType;
}) => linkToAssetDetailPage(entity_type, value);

// IMPROVE: this component needs a big refactor/cleanup
const FormSubmissionDetail = ({
  breadcrumbs,
}: {
  breadcrumbs: LegacyBreadcrumbType[];
}) => {
  const params = useParams<{ formSubmissionId: string }>();
  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const formSubmissionQuery = useGetOneFormSubmission({
    id: params.formSubmissionId,
  });

  const detailsData = formSubmissionQuery.data?.data;

  const submittedByUserQuery = useGetOneUser({
    id: detailsData?.created_by,
  });

  const exportXLSXMutation = useMutation({
    mutationFn: async (formSubmissionId: string) => {
      const respData =
        await FormService.getFormSubmissionXLSXExport(formSubmissionId);

      if (!respData) {
        throw new BaseError("Failed to fetch XLSX export of form submission");
      }

      const fileBlob = respData.data;

      downloadXLSX(
        `${detailsData?.form_schema?.name} - ${detailsData?.id?.slice(0, 7)}`,
        fileBlob
      );
    },
    onSuccess: () => {
      addAlert({
        variant: "success",
        message: `Successfully exported form submission`,
      });
    },
    onError: () => {
      addAlert({
        variant: "error",
        message: "Failed to export form submission.",
      });
    },
  });
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: false,
    metaUserDataMap: {
      [detailsData?.created_by ?? ""]: submittedByUserQuery.data,
    },
  });
  const formCategoryQuery = useQuery({
    queryKey: [
      "forms",
      "categories",
      detailsData?.form_schema?.form_category_id,
    ],
    queryFn: () =>
      FormCategoryAdapter.getOne({
        id: detailsData?.form_schema?.form_category_id ?? "",
      }),
    enabled: !!detailsData?.form_schema?.form_category_id,
  });
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isLoading =
    formSubmissionQuery.isLoading || submittedByUserQuery.isLoading;

  const title = isLoading
    ? "Form Submission Detail"
    : `${detailsData?.form_schema?.name} - ${detailsData?.id?.slice(0, 7)}`;

  const updateMutation = useUpdateOneFormSubmission({
    successMessage: (data: { data: FormSubmissionType }) =>
      `${title} status changed to ${startCase(data.data.status.toLowerCase())}`,
    errorMessage: `Failed to update status of ${title}.`,
  });

  const breadcrumbsWithName = useMemo(() => {
    const breadcrumbsObject = getBreadcrumbsObject(breadcrumbs, params);
    breadcrumbsObject[breadcrumbsObject.length - 1].title = title;
    return breadcrumbsObject;
  }, [breadcrumbs, detailsData]);

  const deleteMutation = useDeleteOneFormSubmission({
    successMessage: `${title} successfully deleted.`,
    errorMessage: `Failed to delete ${title}.`,
    onSuccess: () => {
      navigate({ pathname: linkToFormSubmissionList() });
    },
  });

  const formatQuestionAnswer = (question, answer) => {
    if (question?.data_type === "boolean") {
      return booleanFormatter(answer.value);
    }
    if (question?.data_type === "date") {
      return dateFormatter(new Date(answer.value));
    }
    if (question?.data_type === "date-time") {
      return datetimeFormatter(new Date(answer.value));
    }
    if (question?.data_type === "date-time-range") {
      return `${datetimeFormatter(
        new Date(answer.value[0])
      )} - ${datetimeFormatter(new Date(answer.value[1]))}`;
    }
    if (question?.data_type === "lookup") {
      return (
        <Link
          onClick={() => navigate({ pathname: getLinkPath(answer) })}
          label={answer?.name}
        />
      );
    }

    if (question?.data_type === "file") {
      return (
        <FileDownloadLink
          fileName={answer?.value?.name}
          fileUrl={answer?.value?.ref}
        />
      );
    }

    if (question?.data_type === "geo_point") {
      return formatCoordinateDegrees(answer.value);
    }

    return answer.value;
  };

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
  };
  const statusVariant = useMemo(() => {
    const { type } = getStatusType(detailsData?.status ?? "", "form");

    // map a status type to the appr. summary information container prop
    return (
      {
        success: StatusVariants.ACTIVE,
        info: StatusVariants.INFO,
        warning: StatusVariants.WARNING,
        error: StatusVariants.INACTIVE,
        default: StatusVariants.DEFAULT,
      }[type] ?? StatusVariants.DEFAULT
    );
  }, [detailsData?.status]);

  const data = useMemo(() => {
    const QuestionAnswerPairsBySection = [];
    const details = detailsData;

    if (!details) {
      return null;
    }

    /*
      Algo to stitch together the data: 
      1. iterate through form_schema.config.sections to maintain original section order
      2. iterate through answers[id] to capture repeated sections and in the correct order
      3. filter out any questions that weren't answered, but maintain correct question order
      4. add a number to panel title similar to create form if multiple repeated sections
    */
    details?.form_schema?.config.sections.forEach((uniqueSection) => {
      details.answers[uniqueSection.id].forEach((section, fieldIndex) => {
        const answeredQuestionIds = Object.keys(section);
        const answeredQuestions = uniqueSection.questions.filter((questionId) =>
          answeredQuestionIds.includes(questionId)
        );
        const sectionData = answeredQuestions.map((questionId) => {
          return {
            title: details.form_schema?.config.questions[questionId].prompt,
            value: formatQuestionAnswer(
              details.form_schema?.config.questions[questionId],
              section[questionId]
            ),
          };
        });

        const displayedSectionName =
          details.answers[uniqueSection.id].length > 1
            ? `${uniqueSection.name} ${fieldIndex + 1}`
            : uniqueSection.name;

        if (!isEmpty(section)) {
          QuestionAnswerPairsBySection.push({
            section: { ...uniqueSection, name: displayedSectionName },
            data: sectionData,
          });
        }
      });
    });

    return QuestionAnswerPairsBySection;
  }, [detailsData]);

  const actionRow = [
    <Button
      key="delete-form"
      variant="error-outline"
      onClick={() => setIsDeleteModalOpen(true)}
      disabled={isLoading}
    >
      Delete
    </Button>,
    <DropdownMenu
      key="export-action"
      options={[
        {
          label: "Export as XLSX",
          isDisabled: exportXLSXMutation.isLoading,
          onClick: () => {
            exportXLSXMutation.mutate(params?.formSubmissionId);
          },
        },
        {
          label: "Export as PDF",
          isDisabled: !detailsData || exportPDFMutation.isLoading,
          onClick: () => {
            if (!detailsData) return;

            exportPDFMutation.mutate(detailsData);
          },
        },
      ]}
    >
      <Button
        icon="caret-down"
        iconPosition="right"
        isLoading={exportPDFMutation.isLoading || exportXLSXMutation.isLoading}
      >
        Export
      </Button>
    </DropdownMenu>,
    <Button
      key="update-status"
      variant="primary"
      onClick={() => setIsStatusModalOpen(true)}
    >
      Update Status
    </Button>,
  ];

  return (
    <>
      <Page
        title={title}
        category="Form Submission"
        breadcrumbs={breadcrumbsWithName}
        actionRow={actionRow}
      >
        <Row>
          <Column variant={6}>
            <Panel
              isFluidY={false}
              loaded={!isLoading}
            >
              <SummaryInformationContainer
                variant="vertical"
                status={detailsData?.status}
                statusVariant={statusVariant}
              >
                <SummaryInformation
                  {...sharedProps}
                  title="Form Template"
                  value={
                    detailsData?.form_schema ? (
                      <Link
                        onClick={() => {
                          navigate({
                            pathname: linkToFormTemplateDetail(
                              detailsData?.form_schema?.form_category_id,
                              detailsData?.form_schema?.id
                            ),
                          });
                        }}
                        label={detailsData?.form_schema?.name}
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <SummaryInformation
                  {...sharedProps}
                  isLoading={formCategoryQuery.isLoading}
                  title="Form Category"
                  value={
                    formCategoryQuery.data ? (
                      <Link
                        onClick={() => {
                          navigate({
                            pathname: linkToFormCategoryDetails(
                              formCategoryQuery.data.id
                            ),
                          });
                        }}
                        label={formCategoryQuery.data.name}
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <SummaryInformation
                  {...sharedProps}
                  title="Submitted At"
                  value={getTimeStringFromDate(
                    detailsData?.created_at,
                    config.DATETIME_FORMAT_READABLE
                  )}
                />
                <SummaryInformation
                  {...sharedProps}
                  title="Submitted By"
                  value={submittedByUserQuery.data?.name ?? "-"}
                />
              </SummaryInformationContainer>
            </Panel>
          </Column>
          <Column variant={18}>
            {data?.map((panel, index) => (
              <KeyValuePanel
                panelMaxColumnCount={2}
                key={`${panel?.name}-${index}`}
                data={panel.data}
                panelProps={{ title: panel.section?.name, isFluidY: false }}
              />
            )) ?? null}
          </Column>
        </Row>
      </Page>
      <UpdateStatusModal
        open={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        onUpdate={() => {}}
        doUpdate={async (updateData) => {
          // update the form submission with the updated data provided by the modal
          await updateMutation.mutateAsync({
            id: params.formSubmissionId,
            data: updateData,
          });
        }}
        entityTitle={title}
        currentStatus={detailsData?.status}
      />
      <Dialog
        title="Delete Form?"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actionRow={[
          <Button
            key="delete-warning"
            variant="error"
            onClick={() => {
              deleteMutation.mutate({
                id: params?.formSubmissionId,
              });
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this form submission? This action
          cannot be undone.
        </div>
      </Dialog>
    </>
  );
};

FormSubmissionDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FormSubmissionDetail;
