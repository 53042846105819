import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#src/Routers/hooks";
import { FormTemplatesPanel } from "#src/batteries-included-components/Panels/CardPanels/FormTemplatesPanel";
import { FormSchemaFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/FormSchemaFilterPanel";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import {
  FORM_CATEGORY_DETAILS_BREADCRUMB,
  FORM_CATEGORY_DETAILS_PAGE_TITLE_PLACEHOLDER,
} from "#src/routes/forms/categories/[categoryId]";
import { useQuery } from "@tanstack/react-query";
import { Button, Page } from "@validereinc/common-components";
import { FormCategoryAdapter } from "@validereinc/domain";
import React from "react";
import { linkToCreateFormCategoryFormTemplate } from "./create-form-template";

export const FormCategoryDetailsPage = () => {
  const [canWriteFormSchemas] = useHasPermission("form_schemas:write");
  const [isCreatePageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "dev:beta",
  });

  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const { filterConfigStorageKey } = useStorageKey("form-templates");

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: () => {
      if (!categoryId) {
        return;
      }

      return FormCategoryAdapter.getOne({
        id: categoryId,
      });
    },
    enabled: Boolean(categoryId),
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
    }
  );

  const onCreateTemplate = () => {
    navigate({ pathname: linkToCreateFormCategoryFormTemplate(categoryId) });
  };

  return (
    <Page
      title={
        categoryQuery?.data?.name ??
        FORM_CATEGORY_DETAILS_PAGE_TITLE_PLACEHOLDER
      }
      category="Form Category"
      breadcrumbs={breadcrumbs}
      isLoading={categoryQuery?.isLoading}
      actionRow={
        isCreatePageAvailable && canWriteFormSchemas
          ? [
              <Button
                key="create-template-action"
                onClick={onCreateTemplate}
                variant="primary"
              >
                Create Template
              </Button>,
            ]
          : undefined
      }
    >
      <FormSchemaFilterPanel
        filterConfigStorageKey={filterConfigStorageKey}
        withCategoryField={false}
      />

      <FormTemplatesPanel filterConfigStorageKey={filterConfigStorageKey} />
    </Page>
  );
};
