import { ReorderDirection, ReorderDirectionType } from "@validereinc/domain";

export const moveItem = (
  items: any[],
  move: (from: number, to: number) => void,
  indexToMoveFrom: number,
  direction: ReorderDirectionType
): {
  originalItemId?: string;
  adjacentItemId?: string;
  indexToMoveTo?: number;
} => {
  const indexToMoveTo =
    direction === ReorderDirection.UP
      ? indexToMoveFrom - 1
      : indexToMoveFrom + 1;

  if (indexToMoveTo < 0 || indexToMoveTo > items.length - 1) {
    return {
      originalItemId: undefined,
      adjacentItemId: undefined,
      indexToMoveTo: undefined,
    };
  }

  const originalItemId = items[indexToMoveFrom];
  const adjacentItemId = items[indexToMoveTo];

  move(indexToMoveFrom, indexToMoveTo);

  return {
    originalItemId,
    adjacentItemId,
    indexToMoveTo,
  };
};
