import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  AssetTypeSelection,
  useCompositeAssetFilters,
  AssetTypeSelectionType,
  useAssetCustomAttributeFilters,
} from "../assetFilterComponents";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  WorkflowCategoriesAdapter,
  WorkflowTemplatesAdapter,
} from "@validereinc/domain";
import React from "react";

export const WORKFLOW_ALL_FILTERS = {
  dateRange: {
    name: "date_range",
  },
  category: {
    name: "categoryId",
  },
  template: {
    name: "templateId",
  },
  facility: {
    name: "facilityId",
  },
  equipment: {
    name: "equipmentId",
  },
  device: {
    name: "deviceId",
  },
  flow: {
    name: "flowId",
  },
  asset_group: {
    name: "assetGroupId",
  },
  assetType: {
    name: "assetType",
  },
} as const;

export type WorkflowsFilterPanelFiltersType = {
  [WORKFLOW_ALL_FILTERS.dateRange.name]: { from: string; to: string };
  [WORKFLOW_ALL_FILTERS.category.name]: string;
  [WORKFLOW_ALL_FILTERS.template.name]: string;
  [WORKFLOW_ALL_FILTERS.facility.name]: string[];
  [WORKFLOW_ALL_FILTERS.equipment.name]: string[];
  [WORKFLOW_ALL_FILTERS.device.name]: string[];
  [WORKFLOW_ALL_FILTERS.flow.name]: string[];
  [WORKFLOW_ALL_FILTERS.asset_group.name]: string[];
  [WORKFLOW_ALL_FILTERS.assetType.name]: AssetTypeSelectionType;
  workflow: Record<string, any>;
};

type WorkflowAllFilterPanelProps = {
  hasSelectCategory?: boolean;
  hasSelectTemplate?: boolean;
  defaultAssetType?: AssetTypeSelectionType;
} & Pick<StorageKeys, "filterConfigStorageKey">;

export const WorkflowsFilterPanel = ({
  filterConfigStorageKey,
  hasSelectCategory = true,
  hasSelectTemplate = true,
  defaultAssetType,
}: WorkflowAllFilterPanelProps) => {
  const { localize } = useLocalization();

  const [filters] = useFilters<WorkflowsFilterPanelFiltersType>(
    filterConfigStorageKey
  );
  const assetType = defaultAssetType
    ? defaultAssetType
    : filters.assetType ?? AssetTypeSelection.FACILITY;

  const assetFilters = useCompositeAssetFilters(
    assetType,
    WORKFLOW_ALL_FILTERS
  );

  const attributeFilters =
    useAssetCustomAttributeFilters(assetType).customAttributeFilters;

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={[localize(assetType)]}
      defaultValue={{
        [WORKFLOW_ALL_FILTERS.dateRange.name]:
          DEFAULT_DATE_RANGES.currentWholeYear,
        [WORKFLOW_ALL_FILTERS.category.name]: null,
        [WORKFLOW_ALL_FILTERS.template.name]: null,
        assetType: assetType ?? AssetType.FACILITY,
      }}
      filters={[
        {
          component: (
            <DateSelectorInput
              key={WORKFLOW_ALL_FILTERS.dateRange.name}
              name={WORKFLOW_ALL_FILTERS.dateRange.name}
              variant="month"
              isRange
              isInline
              description="Filter by Created At"
            />
          ),
        },
        ...assetFilters,
        ...(hasSelectCategory
          ? [
              {
                component: (
                  <DropdownInput
                    key={WORKFLOW_ALL_FILTERS.category.name}
                    name={WORKFLOW_ALL_FILTERS.category.name}
                    onFetchData={async (payload) => {
                      let { data } = await WorkflowCategoriesAdapter.getList({
                        ...payload,
                        filters: {
                          ...(payload.searchTerm
                            ? {
                                name: payload.searchTerm,
                              }
                            : {}),
                        },
                      });

                      // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                      if (Array.isArray(payload.value)) {
                        data = data.filter((d) => payload.value.includes(d.id));
                      }

                      return data;
                    }}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Select Category"
                    isInline
                    isMulti
                  />
                ),
              },
            ]
          : []),
        ...(hasSelectTemplate
          ? [
              {
                component: (
                  <DropdownInput
                    key={WORKFLOW_ALL_FILTERS.template.name}
                    name={WORKFLOW_ALL_FILTERS.template.name}
                    onFetchData={async (payload) => {
                      let { data } = await WorkflowTemplatesAdapter.getList({
                        ...payload,
                        filters: {
                          ...(payload.searchTerm
                            ? {
                                name: payload.searchTerm,
                              }
                            : {}),
                        },
                      });

                      // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                      if (Array.isArray(payload.value)) {
                        data = data.filter((d) => payload.value.includes(d.id));
                      }

                      return data;
                    }}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Select Template"
                    isInline
                    isMulti
                  />
                ),
              },
            ]
          : []),
        ...attributeFilters,
      ]}
    />
  );
};
