import { WorkflowTaskUpdateAssigneeDialog } from "#src/batteries-included-components/Dialogs/WorkflowTaskUpdateAssigneeDialog";
import { WorkflowTaskDetailDrawer } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer";
import {
  filterConfig,
  useRelativeDateRange,
  WorkflowTaskFilterPanelFiltersType,
} from "#src/batteries-included-components/Panels/FilterPanels/WorkflowTasksFilterPanel";
import { AssetTypeSelection } from "#src/batteries-included-components/Panels/FilterPanels/assetFilterComponents";
import { WorkflowTaskStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { linkToAssetDetailPage } from "#src/utils/links";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import useLocalization from "#src/hooks/useLocalization";
import {
  useMutation,
  useQueries,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  FilterPills,
  FilterPillVariants,
  HeaderType,
  Link,
  StorageKeys,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  SortDirection,
  UserGroupsAdapter,
  UserGroupType,
  UsersAdapter,
  UserType,
  WorkflowTaskAdapter,
  WorkflowTaskBaseSchema,
  WorkflowTaskStatus,
  WorkflowTaskType,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import startCase from "lodash/startCase";
import React, { useMemo, useState } from "react";
import {
  useListWorkflowTasks,
  useListWorkflowActions,
} from "#src/components/hooks/adapters/useWorkflowTasks";

export type WorkflowTasksTablePanelProps = {
  isDisplayingMyTasks: boolean;
  isDisplayingActions: boolean;
  taskFilters?: Partial<WorkflowTaskType>;
  onRowClick?: (data: WorkflowTaskType) => void;
  onReassignTask?: (task: WorkflowTaskType) => void;
  /** turns off certain columns as they're duplicated in the workflow details */
  isWithinWorkflowDetail?: boolean;
} & StorageKeys;

const isValidId = (assetIds: string[] | undefined) => {
  return !!assetIds?.length;
};

const getAssetFilters = (
  filterInput: Partial<WorkflowTaskFilterPanelFiltersType>
) => {
  const {
    assetType,
    facilityId,
    equipmentId,
    deviceId,
    flowId,
    assetGroupId,
    workflow,
  } = filterInput;
  switch (assetType) {
    case AssetTypeSelection.FACILITY:
      return {
        ...(isValidId(facilityId)
          ? { "workflow.facility.id": facilityId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.facility
          ? toFlattenedObject(workflow.facility, {
              prefix: "workflow.facility",
            })
          : {}),
      };
    case AssetTypeSelection.EQUIPMENT:
      return {
        ...(isValidId(equipmentId)
          ? { "workflow.equipment.id": equipmentId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.equipment
          ? toFlattenedObject(workflow.equipment, {
              prefix: "workflow.equipment",
            })
          : {}),
      };
    case AssetTypeSelection.DEVICE:
      return {
        ...(isValidId(deviceId)
          ? { "workflow.device.id": deviceId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.device
          ? toFlattenedObject(workflow.device, { prefix: "workflow.device" })
          : {}),
      };
    case AssetTypeSelection.FLOW:
      return {
        ...(isValidId(flowId)
          ? { "workflow.flow.id": flowId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.flow
          ? toFlattenedObject(workflow.flow, { prefix: "workflow.flow" })
          : {}),
      };
    case AssetTypeSelection.ASSET_GROUP:
      return {
        ...(isValidId(assetGroupId)
          ? { "workflow.asset_group.id": assetGroupId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.asset_group
          ? toFlattenedObject(workflow.asset_group, {
              prefix: "workflow.asset_group",
            })
          : {}),
      };
    default:
      return {};
  }
};

export const WorkflowTasksTablePanel = <
  TFilters extends WorkflowTaskFilterPanelFiltersType,
>({
  isDisplayingMyTasks,
  isDisplayingActions,
  filterConfigStorageKey,
  tableConfigStorageKey,
  taskFilters,
  onRowClick,
  onReassignTask,
  isWithinWorkflowDetail,
}: WorkflowTasksTablePanelProps) => {
  const sorting = {
    sortBy: "created_at",
    sortDirection: SortDirection.DESCENDING,
  };
  const [storedFilters] = useFilters<TFilters>(filterConfigStorageKey);
  const {
    search,
    ["workflow.due_date"]: dueDate,
    assignee_user,
    assignee_group,
    templateId,
    categoryId,
  } = storedFilters;

  const [selectedTask, setSelectedTask] = useState<WorkflowTaskType | null>(
    null
  );
  const [reassignTask, setReassignTask] = useState<WorkflowTaskType | null>(
    null
  );
  const [statusFilter, setStatusFilter] = useState();

  const defaultRangeRange = useRelativeDateRange();

  const minDate = dueDate?.from ?? defaultRangeRange.from;
  const maxDate = dueDate?.to ?? defaultRangeRange.to;
  const filters: Parameters<typeof WorkflowTaskAdapter.getList>[0]["filters"] =
    {
      name: search,
      ...(statusFilter
        ? {
            status: Array.isArray(statusFilter)
              ? statusFilter
              : { $exact: statusFilter },
          }
        : {}),
      assignee_user,
      assignee_group,
      ...getAssetFilters(storedFilters),
      "workflow_category.id": categoryId,
      "workflow_template.id": templateId,
      $and: [
        taskFilters,
        ...(!isWithinWorkflowDetail
          ? [
              {
                $or: [
                  { [filterConfig.dueDate.id]: null },
                  {
                    $and: [
                      {
                        [filterConfig.dueDate.id]: {
                          $gte: minDate,
                        },
                      },
                      {
                        [filterConfig.dueDate.id]: {
                          $lte: maxDate,
                        },
                      },
                    ],
                  },
                ],
              },
            ]
          : [{}]),
      ],
    };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const requestPayload: Parameters<typeof WorkflowTaskAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  };

  const { addAlert } = useAlert();
  const queryClient = useQueryClient();
  const { localize } = useLocalization();
  const actionsQuery = useListWorkflowActions(requestPayload, {
    enabled: !!isDisplayingActions,
  });
  const tasksQuery = useListWorkflowTasks(requestPayload, {
    enabled: !isDisplayingActions,
  });
  const { data, isLoading: isTasksLoading } = isDisplayingActions
    ? actionsQuery
    : tasksQuery;
  const assignedToUserQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      data?.data.map((task) => {
        return {
          queryKey: ["users", task.assignee_user],
          queryFn: () => {
            if (!task.assignee_user) {
              return;
            }

            return UsersAdapter.getOne({ id: task.assignee_user });
          },
          enabled: Boolean(task.assignee_user),
          select: (resp) => resp?.data,
        };
      }) ?? [],
  });
  const assignedToUserGroupQueries = useQueries({
    queries:
      data?.data.map((task) => {
        return {
          queryKey: ["users", "groups", task.assignee_group],
          queryFn: () => {
            if (!task.assignee_group) return;
            return UserGroupsAdapter.getOne({ id: task.assignee_group });
          },
          enabled: !!task.assignee_group,
        };
      }) ?? [],
  });

  const filterPills = [
    {
      name: "All",
      label: "All",
      value: null,
      isSelected: true,
    },
    {
      name: WorkflowTaskStatus.OPEN,
      label: startCase(WorkflowTaskStatus.OPEN),
      value: WorkflowTaskStatus.OPEN,
      variant: FilterPillVariants.PENDING,
      isSelected: statusFilter,
    },
    {
      name: WorkflowTaskStatus.COMPLETE,
      label: startCase(WorkflowTaskStatus.COMPLETE),
      value: WorkflowTaskStatus.COMPLETE,
      variant: FilterPillVariants.GOOD,
      isSelected: statusFilter,
    },
    {
      name: WorkflowTaskStatus.DISMISSED,
      label: startCase(WorkflowTaskStatus.DISMISSED),
      value: WorkflowTaskStatus.DISMISSED,
      variant: FilterPillVariants.NEUTRAL,
      isSelected: statusFilter,
    },
  ];

  const { mutate: updateTask } = useMutation({
    mutationFn: (
      payload: Parameters<typeof WorkflowTaskAdapter.updateOne>[0]
    ) => WorkflowTaskAdapter.updateOne(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
      setTimeout(
        () =>
          queryClient.refetchQueries({
            queryKey: ["workflows"],
          }),
        4000
      );
      addAlert({
        variant: "success",
        message: "Successfully updated task",
      });
    },
    onError: (err) => {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Unable to update task",
      });
    },
  });

  const assignedToUserMap = useMemo(
    () =>
      assignedToUserQueries.reduce<Record<string, UserType>>((map, q) => {
        if (!q.data) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      }, {}),
    [assignedToUserQueries]
  );
  const assignedToUserGroupMap = useMemo(
    () =>
      assignedToUserGroupQueries.reduce<Record<string, UserGroupType>>(
        (map, q) => {
          if (!q.data) {
            return map;
          }

          map[q.data.data.id] = q.data.data;
          return map;
        },
        {}
      ),
    [assignedToUserGroupQueries]
  );

  const actionsHeaders: Array<HeaderType<WorkflowTaskType>> = [
    {
      label: "Title",
      key: WorkflowTaskBaseSchema.keyof().Enum.name,
      isSortable: true,
      renderComponent: ({ item }) => (
        <Link
          label={item?.name}
          onClick={() => {
            setSelectedTask(item);
            onRowClick?.(item);
          }}
        />
      ),
    },
    {
      label: "Description",
      key: WorkflowTaskBaseSchema.keyof().Enum.description,
      isSortable: true,
    },
    {
      label: "Status",
      key: WorkflowTaskBaseSchema.keyof().Enum.status,
      isSortable: true,
      renderComponent: ({ item }) => {
        return (
          item.status && (
            <DataTable.DataRow.PillCell
              variant={
                WorkflowTaskStatusToPillVariantMap[item.status] || "default"
              }
              value={toStartCaseString(item.status)}
            />
          )
        );
      },
    },
    {
      label: "Action Type",
      key: WorkflowTaskBaseSchema.keyof().Enum.type,
      isSortable: true,
      renderComponent: ({ item }) => (
        <div>
          {`${toStartCaseString(item.step_type)} - ${toStartCaseString(item.type)}`}
        </div>
      ),
    },
    {
      label: "Assigned to",
      key: WorkflowTaskBaseSchema.keyof().Enum.assignee_user,
      isSortable: true,
      renderComponent: ({ item }) => {
        if (
          item.assignee_group &&
          assignedToUserGroupMap[item.assignee_group]
        ) {
          return (
            <RoutingLink
              to={UserGroupDetailsRoutePath.toLink({
                pathParams: { groupId: item.assignee_group },
              })}
            >
              {assignedToUserGroupMap[item.assignee_group].name}
            </RoutingLink>
          );
        } else if (
          item.assignee_user &&
          assignedToUserMap[item.assignee_user]
        ) {
          return (
            <RoutingLink to={linkToUserDetailPage(item.assignee_user)}>
              {assignedToUserMap[item.assignee_user].name}
            </RoutingLink>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];

  const tasksHeaders: Array<HeaderType<WorkflowTaskType>> = [
    {
      label: "Title",
      key: WorkflowTaskBaseSchema.keyof().Enum.name,
      isSortable: true,
      renderComponent: ({ item }) => (
        <Link
          label={item?.name}
          onClick={() => {
            setSelectedTask(item);
            onRowClick?.(item);
          }}
        />
      ),
    },
    {
      label: "Description",
      key: WorkflowTaskBaseSchema.keyof().Enum.description,
      isSortable: true,
    },
    {
      label: "Assigned to",
      key: WorkflowTaskBaseSchema.keyof().Enum.assignee_user,
      isSortable: true,
      renderComponent: ({ item }) => {
        if (
          item.assignee_group &&
          assignedToUserGroupMap[item.assignee_group]
        ) {
          return (
            <RoutingLink
              to={UserGroupDetailsRoutePath.toLink({
                pathParams: { groupId: item.assignee_group },
              })}
            >
              {assignedToUserGroupMap[item.assignee_group].name}
            </RoutingLink>
          );
        } else if (
          item.assignee_user &&
          assignedToUserMap[item.assignee_user]
        ) {
          return (
            <RoutingLink to={linkToUserDetailPage(item.assignee_user)}>
              {assignedToUserMap[item.assignee_user].name}
            </RoutingLink>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      label: "Task Type",
      key: WorkflowTaskBaseSchema.keyof().Enum.type,
      isSortable: true,
      renderComponent: ({ item }) => toStartCaseString(item.type) ?? "-",
    },
    ...(!isWithinWorkflowDetail
      ? [
          {
            label: "Workflow",
            key: WorkflowTaskBaseSchema.keyof().Enum.workflow_id,
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) => (
              <RoutingLink
                to={WorkflowDetailsRoutePath.toLink({
                  pathParams: {
                    workflowId: item.workflow_id,
                  },
                })}
              >
                {item.workflow.workflow_template.name}
              </RoutingLink>
            ),
          },
          {
            label: "Workflow Due Date",
            key: "workflow.due_date",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) => (
              <DataTable.DataRow.DateCell value={item.workflow.due_date} />
            ),
          },
          {
            label: "Asset",
            isSortable: false,
            key: "asset.name",
            renderComponent: ({ item }: { item: WorkflowTaskType }) =>
              item.workflow.asset ? (
                <RoutingLink
                  to={linkToAssetDetailPage(
                    item.workflow.asset.asset_type,
                    item.workflow.asset.id
                  )}
                >
                  {item.workflow.asset.name}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
          {
            label: "Asset Type",
            isSortable: false,
            key: "asset.type",
            renderComponent: ({ item }: { item: WorkflowTaskType }) =>
              item.workflow.asset
                ? localize(item.workflow.asset.asset_type)
                : "-",
          },
          {
            label: `Associated ${localize("facility")}`,
            key: "workflow.facility.id",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) =>
              item.workflow.asset &&
              item.workflow.asset.asset_type !== AssetType.FACILITY &&
              item.workflow.facility ? (
                <RoutingLink
                  to={linkToFacilityDetail(item.workflow.facility.id)}
                >
                  {item.workflow.facility.name}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    {
      key: WorkflowTaskBaseSchema.keyof().Enum.created_at,
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.created_at} />
      ),
    },
    {
      label: "Status",
      key: WorkflowTaskBaseSchema.keyof().Enum.status,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={WorkflowTaskStatusToPillVariantMap[item.status] || "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
  ];

  const isLoading =
    isTasksLoading ||
    assignedToUserQueries.some((q) => q.isFetching && !q.isRefetching) ||
    assignedToUserGroupQueries.some((q) => q.isFetching && !q.isRefetching);

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: `${isDisplayingActions ? "Actions" : isDisplayingMyTasks ? "My Tasks" : "All Tasks"}`,
          titleDecorator: (
            <FilterPills
              name="status"
              pills={filterPills}
              onChange={setStatusFilter}
            />
          ),
        }}
        dataTableProps={{
          headers: isDisplayingActions ? actionsHeaders : tasksHeaders,
          items: data?.data ?? [],
          sorting,
          isLoading,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: data?.total_entries ?? 0,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions: ({ item }: { item: WorkflowTaskType }) =>
            item.status === "open"
              ? [
                  {
                    label: "Complete",
                    buttonProps: {
                      icon: "check-circle",
                      onClick: () =>
                        updateTask({
                          id: item.id,
                          data: {
                            status: WorkflowTaskStatus.COMPLETE,
                          },
                          previousData: item,
                        }),
                    },
                  },
                  {
                    label: "Dismiss",
                    buttonProps: {
                      icon: "x-circle",
                      onClick: () =>
                        updateTask({
                          id: item.id,
                          data: {
                            status: WorkflowTaskStatus.DISMISSED,
                          },
                          previousData: item,
                        }),
                    },
                  },
                  {
                    label: "Reassign",
                    buttonProps: {
                      icon: "user-circle-gear",
                      onClick: () => {
                        setReassignTask(item);
                        onReassignTask?.(item);
                      },
                    },
                  },
                ]
              : [],
        }}
      />
      <WorkflowTaskDetailDrawer
        setSelectedTask={setSelectedTask}
        selectedTask={selectedTask}
        onReassignTask={(task) => setReassignTask(task)}
      />
      <WorkflowTaskUpdateAssigneeDialog
        isOpen={Boolean(reassignTask)}
        task={reassignTask}
        onClose={() => setReassignTask(null)}
      />
    </>
  );
};
