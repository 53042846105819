import { RecordValueDetailsPanel } from "#batteries-included-components/Panels/DetailsPanel/RecordValueDetailsPanel";
import { RecordValueSourcesTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueSourcesTablePanel";
import { useParams } from "#routers/hooks";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import BulkRecordValueStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordValueStatusChangeButton/BulkRecordValueStatusChangeButton";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordValueSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordValueSummaryPanel/RecordValueSummaryPanel";
import { RecordValueActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueActivityLogTablePanel";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Column, Page, Row } from "@validereinc/common-components";
import { AssetType, Resources } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  FlowRecordValueDetailRoute,
  FlowRecordValuePageParamsType,
} from "./index";
import useLocalization from "#src/hooks/useLocalization";

const FlowRecordValueDetailPageContent = () => {
  const { localize } = useLocalization();
  const params = useParams<FlowRecordValuePageParamsType>();
  const { filterConfigStorageKey } = useStorageKey("flow-detail-filters");

  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const { record, refetch } = useContext(RecordContext) || {};

  const { measurementTypes } = useMeasurementTypes();
  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordValueDetailRoute.previous?.title;

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : FlowRecordValueDetailRoute.title;

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordValueDetailRoute, {
    detail: { title: flow?.name },
    recordId: { title: recordPageTitle },
    measurementType: { title: pageTitle },
  });

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType?.id
  );

  const tableKeys = useStorageKey(
    `activity-logs-${Resources.RECORD_VALUE}-${recordValue?.id}`
  );

  return (
    <Page
      category={`${localize("Flow")} Record`}
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      actionRow={
        <BulkRecordValueStatusChangeButton
          recordId={record?.id ?? ""}
          measurementTypes={[recordValue?.measurement_type ?? ""]}
          onSuccess={() => {
            refetch?.();
          }}
          variant="primary"
          label="Change Record Value Status"
        />
      }
    >
      <Row>
        <Column variant={6}>
          <RecordValueSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordValueDetailsPanel />
          <RecordValueSourcesTablePanel type={AssetType.FLOW} />
          <RecordValueActivityLogTablePanel
            recordId={record?.id ?? ""}
            measurement_type={recordValue?.measurement_type}
            {...tableKeys}
          />
        </Column>
      </Row>
    </Page>
  );
};

export const FlowRecordValueDetailPage = () => (
  <RecordProvider>
    <FlowRecordValueDetailPageContent />
  </RecordProvider>
);
