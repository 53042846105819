import {
  Accordion,
  Button,
  CheckboxInput,
  Drawer,
  DropdownInput,
  Form,
  Info,
  Switch,
  TextAreaInput,
  TextInput,
  useAlert,
  useForm,
  useFormContext,
} from "@validereinc/common-components";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { LookupQuestionFilters } from "./LookupQuestionFilters";
import { MeasurementFields } from "./MeasurementFields";
import { QuestionConditions } from "./QuestionConditions";
import { QuestionFields } from "./QuestionFields";
import type { DrawerProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import styles from "./CreateFormTemplateAddQuestionDrawer.module.scss";
import { FormSchemaDisplayQuestionType } from "@validereinc/domain";

const cx = classNames.bind(styles);

const IS_REQUIRED_TOOLTIP_CONTENT =
  "The user will not be able to submit the form without inputting a response to this question.";

const { AccordionPanel } = Accordion;

export const CreateFormTemplateAddQuestionDrawer = ({
  isOpen,
  onClose,
  onSave: onSaveCallback,
  handleDeleteQuestion,
  questionToEdit,
  sectionNumber,
  sectionQuestionsLength,
  displayQuestions,
}: Pick<DrawerProps, "isOpen" | "onClose"> & {
  onSave: (newQuestionId: string) => void;
  isDeleteQuestionDialogOpen: boolean;
  handleDeleteQuestion: (questionId: string) => void;
  questionToEdit: FormSchemaDisplayQuestionType | null;
  sectionNumber: number;
  sectionQuestionsLength: number;
  displayQuestions: FormSchemaDisplayQuestionType[];
}) => {
  const { addAlert } = useAlert();

  const parentForm = useFormContext();

  const questions = parentForm.watch("config.questions");
  const form = useForm({
    defaultValues: questionToEdit
      ? { ...questionToEdit }
      : {
          prompt: "",
          description: "",
          type: null,
          question_number: null,
          is_required: true,
          conditions: null,
          filter: null,
        },
  });

  const values = form.watch();

  const [isConditional, setIsConditional] = useState(
    !!questionToEdit?.conditions
  );
  const [isFiltered, setIsFiltered] = useState(!!questionToEdit?.conditions);

  const onSave = form.handleSubmit((newValues) => {
    const currentQuestions = parentForm.getValues("config.questions");

    const { conditions, ...restQuestion } = newValues;
    const reducedConditions = conditions?.reduce(
      (total, { operation, comparator, question }) => {
        return {
          ...total,
          [question]:
            operation === "$exists"
              ? true
              : {
                  ...(total?.[question] ?? {}),
                  [operation]: comparator,
                },
        };
      },
      {}
    );

    if (questionToEdit) {
      currentQuestions[questionToEdit.id] = {
        ...restQuestion,
        conditions: reducedConditions,
      };

      parentForm.setValue("config.questions", {
        ...currentQuestions,
      });

      addAlert?.({
        variant: "success",
        message: `Successfully edited question ${restQuestion.question_number} in section ${sectionNumber}`,
      });
    } else {
      const newQuestionId = uuid();
      const questionNumber = sectionQuestionsLength + 1;

      parentForm.setValue("config.questions", {
        ...currentQuestions,
        [newQuestionId]: {
          ...restQuestion,
          question_id: newQuestionId,
          conditions: reducedConditions,
          question_number: questionNumber,
        },
      });
      addAlert?.({
        variant: "success",
        message: `Successfully configured question ${questionNumber} in section ${sectionNumber}`,
      });
      onSaveCallback(newQuestionId);
    }

    onCloseCallback();
  });

  const onDelete = () => {
    handleDeleteQuestion(questionToEdit?.id ?? "");
    if (!questionToEdit) {
      onCloseCallback();
    }
  };

  const onCloseCallback = () => {
    setIsConditional(false);
    setIsFiltered(false);
    onClose();
    form.reset();
  };

  const onChangeIsConditional = (newIsConditional: boolean) => {
    if (newIsConditional) {
      form.setValue("conditions", [
        { question: "", operation: "$exists", value: "", type: "$exists" },
      ]);
    } else {
      form.unregister("conditions");
    }

    setIsConditional(newIsConditional);
  };

  const onChangeIsFiltered = (newIsFiltered: boolean) => {
    if (newIsFiltered) {
      form.setValue("filter", [{}]);
    } else {
      form.unregister("filter");
    }

    setIsFiltered(newIsFiltered);
  };

  useEffect(() => {
    setIsConditional(!!questionToEdit?.conditions);
    setIsFiltered(!!questionToEdit?.filter);
  }, [questionToEdit]);

  const questionsArray = Object.entries(questions ?? {}).map(
    ([questionId, question]) => {
      const questionNumber = displayQuestions.filter((displayQuestion) => {
        return displayQuestion.id === questionId;
      })[0]?.question_number;

      return {
        question_id: questionId,
        question_number: questionNumber,
        label: `Section ${sectionNumber}, Question ${questionNumber}: ${question.prompt}`,
        ...question,
      };
    }
  );

  const deviceQuestions = questionsArray?.filter(
    ({ type, data_type }) => type === "question" && data_type === "device"
  );

  return (
    <Drawer
      title="Configure Question"
      isOpen={isOpen}
      onClose={onCloseCallback}
      actionRow={[
        <Button
          key="delete-question-button"
          variant="error-outline"
          onClick={onDelete}
        >
          Delete
        </Button>,
        <Button
          key="save-question-button"
          onClick={onSave}
          variant="primary"
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <Accordion defaultActiveKeys={["question-overview"]}>
          <AccordionPanel
            dataKey="question-overview"
            title="Question Overview"
            isError={[
              "prompt",
              "description",
              "type",
              "data_type",
              "measurement_type",
              "measurement_unit",
              "source_lookup_question_id",
              "subject_lookup_question_id",
            ].some((key) => get(form.formState.errors, key))}
          >
            <>
              <TextInput
                label="Prompt"
                name="prompt"
                isRequired
                validate={{
                  maxLength: (prompt) =>
                    prompt.length < 100 ||
                    "Prompt must be less than 100 characters.",
                }}
              />

              <TextAreaInput
                label="Description"
                name="description"
                validate={{
                  maxLength: (description) =>
                    description.length < 1024 ||
                    "Description must be less than 1024 characters.",
                }}
              />

              <div className={cx("checkbox-container")}>
                <CheckboxInput
                  name="is_required"
                  label="Question is required"
                  isLabelShown={false}
                  className={cx("checkbox")}
                />
                <Info content={IS_REQUIRED_TOOLTIP_CONTENT} />
              </div>

              <DropdownInput
                label="Question Type"
                name="type"
                options={[
                  { label: "Question", value: "question" },
                  { label: "Measurement", value: "measurement" },
                ]}
                labelKey="label"
                valueKey="value"
                isRequired
              />

              {values.type === "question" ? <QuestionFields /> : null}

              {values.type === "measurement" ? (
                <MeasurementFields deviceQuestions={deviceQuestions} />
              ) : null}

              {values?.data_type === "lookup" && values?.lookup_entity_type ? (
                <div className={cx("switch-container")}>
                  <Switch
                    name="isFiltered"
                    label="Filter lookup options"
                    value={isFiltered}
                    onChange={onChangeIsFiltered}
                  />
                </div>
              ) : null}

              {questionsArray?.length &&
              !(
                questionsArray?.length === 1 &&
                questionsArray[0].question_id === values.id
              ) ? (
                <Switch
                  name="isConditional"
                  label="Define question conditions"
                  value={isConditional}
                  onChange={onChangeIsConditional}
                />
              ) : null}
            </>
          </AccordionPanel>

          {isFiltered ? (
            <AccordionPanel
              dataKey="question-filters"
              title="Filter Options"
              isError={get(form.formState.errors, "filter")}
            >
              <LookupQuestionFilters
                defaultFilters={questionToEdit?.filter}
                questions={questionsArray.filter((question) => {
                  return question?.question_id !== values?.id;
                })}
              />
            </AccordionPanel>
          ) : null}

          {isConditional ? (
            <AccordionPanel
              dataKey="question-conditions"
              title="Conditionality"
              isError={get(form.formState.errors, "conditions")}
            >
              <QuestionConditions
                defaultConditions={questionToEdit?.conditions}
                questions={questionsArray.filter((question) => {
                  return question?.question_id !== values?.id;
                })}
              />
            </AccordionPanel>
          ) : null}
        </Accordion>
      </Form>
    </Drawer>
  );
};
