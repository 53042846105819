import {
  Button,
  Form,
  useFieldArray,
  useForm,
  useFormContext,
} from "@validereinc/common-components";
import React, { useEffect } from "react";
import { LookupQuestionFilter } from "./LookupQuestionFilter";
import { getQueryObjectForQuestionType } from "./utils";
import { validate as uuidValidate } from "uuid";
import { FormQuestionLookupFilterType } from "@validereinc/domain";
import classNames from "classnames/bind";
import styles from "../../../../CreateFormTemplate.module.scss";

const cx = classNames.bind(styles);

export const LookupQuestionFilters = ({ defaultFilters, questions }) => {
  const parentForm = useFormContext();
  const lookupEntityType = parentForm.watch("lookup_entity_type");

  const getDefaultFilters = (): FormQuestionLookupFilterType[] => {
    const filters: FormQuestionLookupFilterType[] = [];

    for (const [attribute, value] of Object.entries(defaultFilters)) {
      let isFilterDynamic;
      if (typeof value === "string") {
        isFilterDynamic = uuidValidate(value.replaceAll("%", ""));
      }

      filters.push({
        attribute: attribute,
        filterType: isFilterDynamic ? "dynamic" : "static",
        staticValue: !isFilterDynamic ? value : undefined,
        referenceQuestion:
          isFilterDynamic && typeof value === "string"
            ? value.replaceAll("%", "")
            : undefined,
      });
    }

    return filters;
  };

  const { watch, handleSubmit, control, ...restForm } = useForm({
    defaultValues: {
      filters: defaultFilters
        ? getDefaultFilters()
        : [{ attribute: "", filterType: "static" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "filters",
    rules: { minLength: 1 },
    control,
  });

  const onSubmit = ({ filters }) => {
    parentForm.setValue(
      "filter",
      filters?.reduce(
        (
          accumulator,
          { attribute, filterType, referenceQuestion, staticValue, comparator }
        ) => {
          const queryObject = getQueryObjectForQuestionType({
            comparator,
            value:
              filterType === "static" ? staticValue : `%${referenceQuestion}%`,
          });

          return { ...accumulator, [attribute]: queryObject };
        },
        {}
      )
    );
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Button
        className={cx("addFilterButton")}
        variant="outline"
        icon="plus-circle"
        onClick={() => {
          append({ attribute: "", filterType: "static" });
        }}
      >
        Add Filter
      </Button>

      <Form {...{ handleSubmit, watch, control, ...restForm }}>
        {fields.map((field, fieldIndex) => (
          <LookupQuestionFilter
            defaultField={field}
            key={field.id}
            name={`filters.${fieldIndex}`}
            questions={questions}
            id={field.id}
            index={fieldIndex}
            onRemove={remove}
            lookupEntityType={lookupEntityType}
          />
        ))}
      </Form>
    </>
  );
};
