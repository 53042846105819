import {
  DEFAULT_QUERY_OPTIONS,
  NON_INVALIDATING_QUERY_OPTIONS,
  useCreateOne,
  useUpdateOne,
} from "#hooks/adapters/adapterUtils";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  EquipmentDomain,
  EquipmentDomainV2,
  EquipmentType,
  EquipmentTypeDomain,
  HTTPNotFoundErrorCtx,
  ListEquipmentParametersType,
  Resources,
} from "@validereinc/domain";
import { dateFormatter, downloadLink } from "@validereinc/utilities";
import { DEFAULT_INVALIDATE_OPTIONS } from "./adapterUtils";

const EQUIPMENT_QUERY_KEY = "equipment";
const EQUIPMENT_TYPE_QUERY_KEY = "equipmentType";

export const useGetOneEquipment = (
  id?: string,
  meta: { period?: string } = {}
) => {
  const { localize } = useLocalization();

  return useQuery({
    queryKey: [EQUIPMENT_QUERY_KEY, id!, meta] as const,
    queryFn: ({ queryKey: [_, id, meta] }) =>
      EquipmentDomainV2.getOne({ id, meta }),
    enabled: Boolean(id),
    select: (resp) => resp?.data,
    meta: {
      errorCtx: {
        resource: localize("Equipment"),
        resourceId: id,
      } satisfies HTTPNotFoundErrorCtx,
      hideErrorToasts: true,
    },
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useListEquipment = (
  params: ListEquipmentParametersType = {},
  options: {
    select?: any;
    enabled?: boolean;
  } = {}
) =>
  useQuery({
    queryKey: [EQUIPMENT_QUERY_KEY, "getEquipment", params],
    queryFn: () => EquipmentDomain.getEquipment(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListEquipmentTypes = () =>
  useQuery({
    queryKey: [EQUIPMENT_TYPE_QUERY_KEY, "getAll"],
    queryFn: () => EquipmentTypeDomain.getAll(),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });

export const useExportEquipment = (apiParams = {}, exportName?: string) => {
  const { addAlert } = useAlert();
  const {
    v2: { companyInfo },
  } = useAuthenticatedContext();

  const getFileName = () =>
    [
      companyInfo?.company?.name,
      exportName,
      "Equipment_List",
      dateFormatter(new Date()),
    ]
      .filter((part) => !!part)
      .join("_");

  return useMutation({
    mutationFn: async () => {
      const report = await EquipmentDomain.exportEquipment(apiParams);
      downloadLink(report.s3_download_link, getFileName());
    },
    onError: (error) => {
      console.error(error);
      addAlert?.({
        variant: "error",
        message: "Unable to export equipment list",
      });
    },
  });
};

export const useClearEquipmentCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [EQUIPMENT_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};

export const useUpdateEquipment = useUpdateOne<EquipmentType>(
  EquipmentDomainV2.updateOne,
  Resources.EQUIPMENT
);

export const useCreateOneEquipment = useCreateOne<EquipmentType>(
  EquipmentDomainV2.createOne,
  Resources.EQUIPMENT
);
