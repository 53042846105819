import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFacilities } from "#routers/links";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import EditFacilityDialog from "#src/batteries-included-components/Dialogs/EditFacilityDialog";
import { FacilityListFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/FacilityListFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  SortingType,
  StorageKeys,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilityDomain,
  FacilityType,
  SortDirection,
} from "@validereinc/domain";
import {
  downloadLink,
  getYearMonthFromDateRange,
  toFlattenedObject,
  toStartCaseString,
} from "@validereinc/utilities";
import React, { useMemo, useState } from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const FacilityTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { addAlert } = useAlert();
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [facilityToDelete, setFacilityToDelete] = useState<FacilityType>();
  const [isExporting, setIsExporting] = useState(false);
  const [rawFilters] = useFilters<any>(filterConfigStorageKey);

  const deleteFacilityOnClick = async () => {
    try {
      if (facilityToDelete) {
        await FacilityDomain.deleteOne({
          id: facilityToDelete.id,
        });
        addAlert({
          variant: "success",
          message: `Successfully deleted '${facilityToDelete.name}'`,
        });
      }
    } finally {
      refetch();
      setFacilityToDelete(undefined);
    }
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    rawFilters
  );

  const { customAttributeHeaders } = useCustomAttributeFilters({
    assetType: AssetType.FACILITY,
  });

  const staticHeaders: Array<HeaderType<FacilityType>> = [
    {
      label: `${localize("Facility")} Name`,
      key: "name",
      isSortable: true,
      minWidth: 240,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilities(item?.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
  ];

  const headers = useMemo(
    () => [...staticHeaders, ...customAttributeHeaders],
    [staticHeaders, customAttributeHeaders]
  );

  const { savedFilter: _, period, ...filters } = rawFilters;
  const params = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters, {
      transformObjectValue: (val) => val?.id,
    }),
    period: getYearMonthFromDateRange(period),
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["facilities", params],
    queryFn: () => FacilityDomain.getList(params),
  });

  const items = data?.data ?? [];

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { name: file_name, s3_download_link } =
        await FacilityDomain.exportList({
          ...params,
        });

      downloadLink(s3_download_link, file_name);
    } catch (err) {
      console.error(err);
    } finally {
      setIsExporting(false);
    }
  };

  const actionRow = (
    <>
      <Button
        key="export-facilities"
        variant="outline"
        onClick={handleExport}
        isLoading={isExporting}
      >
        Export
      </Button>
      {isDataIngestionEnabled ? (
        <ImportDataAction
          key="import-facility"
          resource={{
            id: AssetType.FACILITY,
            name: "Facility",
            singular: localize("Facility"),
            plural: localize("facility_plural"),
          }}
        />
      ) : null}
    </>
  );

  const getItemActions = ({ item }: { item: FacilityType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => setAssetIdToEdit(item.id),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setFacilityToDelete(item),
        icon: "trash",
      },
    },
  ];

  const loading = isLoading || isMappingLoading;

  return (
    <>
      <FacilityListFilterPanel
        filterConfigStorageKey={filterConfigStorageKey}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        dataTableProps={{
          headers,
          items,
          isLoading: loading,
          getItemActions,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: data?.total_entries ?? 0,
          },
        }}
        panelProps={{
          title: localize("facility_plural"),
          actionRow,
        }}
      />
      <Dialog
        isOpen={!!facilityToDelete}
        onClose={() => setFacilityToDelete(undefined)}
        title={`Delete "${facilityToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={deleteFacilityOnClick}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this {localize("facility")}? This
          action cannot be undone.
        </div>
      </Dialog>
      <EditFacilityDialog
        defaultPeriod={getYearMonthFromDateRange(period) ?? ""}
        facilityId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
        onEdit={() => {
          refetch();
        }}
      />
    </>
  );
};
