import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useSearchParams } from "#routers/hooks";
import { EstimationMethodListFilterPanel } from "#routes/calculations/estimation-methods/EstimationMethodList/EstimationMethodListFilterPanel";
import { EstimationMethodListTablePanel } from "#routes/calculations/estimation-methods/EstimationMethodList/EstimationMethodListTablePanel";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";
import {
  CALCULATIONS_ESTIMATION_METHODS_BREADCRUMB,
  CALCULATIONS_ESTIMATION_METHODS_PAGE_TITLE,
} from ".";
import { CALCULATIONS_BREADCRUMB } from "..";
import useLocalization from "#src/hooks/useLocalization";

const DEFAULT_TAB_KEY = "equipment";

export const EstimationMethodListPage = () => {
  const { localize } = useLocalization();
  const equipmetStorageKeys = useStorageKey("equipment-estimation-methods");
  const flowStorageKeys = useStorageKey("flow-estimation-methods");
  const [{ tab }, setSearchParams] = useSearchParams();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  return (
    <Page
      title={CALCULATIONS_ESTIMATION_METHODS_PAGE_TITLE}
      breadcrumbs={useBreadcrumbs([
        CALCULATIONS_BREADCRUMB,
        CALCULATIONS_ESTIMATION_METHODS_BREADCRUMB,
      ])}
      activeTabKey={tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      <Tab
        title="Equipment"
        tabKey="equipment"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              id: "estimation_method",
              name: "Estimation Methods",
              singular: "Estimation Method",
              plural: "Estimation Methods",
            }}
          />
        ) : null}
        <EstimationMethodListFilterPanel
          filterConfigStorageKey={equipmetStorageKeys.filterConfigStorageKey}
          assetType={AssetType.EQUIPMENT}
        />
        <EstimationMethodListTablePanel
          {...equipmetStorageKeys}
          assetType={AssetType.EQUIPMENT}
        />
      </Tab>
      <Tab
        title={`${localize("Flow")}`}
        tabKey="flow"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              id: "estimation_method",
              name: "Estimation Methods",
              singular: "Estimation Method",
              plural: "Estimation Methods",
            }}
          />
        ) : null}
        <EstimationMethodListFilterPanel
          filterConfigStorageKey={flowStorageKeys.filterConfigStorageKey}
          assetType={AssetType.FLOW}
        />
        <EstimationMethodListTablePanel
          {...flowStorageKeys}
          assetType={AssetType.FLOW}
        />
      </Tab>
    </Page>
  );
};
