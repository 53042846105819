import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";

type simpleTypes = string | number | boolean | Date;

export const $in = ({
  value,
  comparator,
}: {
  value: string | number | string[] | number[];
  comparator: Array<string | number>;
}) =>
  Array.isArray(value)
    ? comparator.some(
        (comparatorValue) =>
          (value as number[]).includes(comparatorValue as number) ||
          (value as string[]).includes(comparatorValue as string)
      )
    : comparator.includes(value);

export const $eq = ({
  value,
  comparator,
}: {
  value: simpleTypes;
  comparator: simpleTypes;
}) => {
  switch (typeof value) {
    case "boolean":
      return value === comparator;
    case "string":
      return value === String(comparator);
    case "number":
      return value === Number(comparator);
    case "object":
      return value.getTime() === (comparator as Date).getTime();
    default:
      return false;
  }
};

export const $lt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value < comparator;

export const $lte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value <= comparator;

export const $gt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value > comparator;

export const $gte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value >= comparator;

const conditionFunctions = {
  $in,
  $eq,
  $lt,
  $lte,
  $gt,
  $gte,
};

type conditionFunctionTypes = keyof typeof conditionFunctions;

type ConditionStatement =
  | simpleTypes
  | Record<conditionFunctionTypes, simpleTypes>;

export type QuestionConditionType = Record<string, ConditionStatement>;

type FormQuestionConditions =
  | QuestionConditionType
  | { $or: QuestionConditionType[] }
  | object;

const isQuestionMatched = ({
  values,
  fieldName,
  key,
  value,
}: {
  values: Record<string, simpleTypes>;
  fieldName: string;
  key: string;
  value: ConditionStatement;
}) => {
  const parentFieldName = getParentQuestionName({
    currentQuestionId: fieldName,
    formValues: values,
    questionIdToFind: key,
  });
  return !isPlainObject(value)
    ? $eq({
        value: value as simpleTypes,
        comparator: get(values, parentFieldName),
      })
    : Object.entries(value).every(([condition, comparator]) => {
        return conditionFunctions?.[condition as conditionFunctionTypes]({
          value: get(values, parentFieldName) as number &
            ((string | number | string[] | number[]) & simpleTypes),
          comparator,
        });
      });
};

export const areConditionsSatisfied = ({
  conditions,
  values,
  fieldName,
}: {
  conditions?: FormQuestionConditions | string;
  values: Record<string, simpleTypes>;
  fieldName: string;
}) => {
  if (!conditions) {
    return true;
  }

  let formattedConditions: FormQuestionConditions;

  if (typeof conditions === "string") {
    formattedConditions = JSON.parse(conditions);
  } else {
    formattedConditions = conditions;
  }

  return Object.entries(formattedConditions).every(
    ([key, questionConditions]) => {
      if (key === "$or")
        return questionConditions.find(
          (questionCondition: QuestionConditionType) =>
            Object.entries(questionCondition).every(
              ([questionToFind, conditionStatement]) =>
                isQuestionMatched({
                  values,
                  fieldName,
                  key: questionToFind,
                  value: conditionStatement,
                })
            )
        );
      else
        return isQuestionMatched({
          values,
          fieldName,
          key,
          value: questionConditions,
        });
    }
  );
};

export const getParentQuestionName = ({
  currentQuestionId,
  formValues,
  questionIdToFind,
}: {
  currentQuestionId: string;
  formValues: Record<string, unknown>;
  questionIdToFind: string;
}) => {
  if (currentQuestionId && questionIdToFind && formValues) {
    const splitName = currentQuestionId?.split(".");

    const nameStart = `answers.${splitName[1]}`;

    const value = get(
      formValues,
      `${nameStart}.${splitName[2]}.${questionIdToFind}`
    );

    if (value) {
      return `${nameStart}.${splitName[2]}.${questionIdToFind}.value`;
    }

    const fieldName = Object.entries(formValues?.answers ?? {}).find(
      ([key, objectValue]) =>
        key !== splitName[1] && objectValue?.[0]?.[questionIdToFind]
    )?.[0];

    return `answers.${fieldName}.0.${questionIdToFind}.value`;
  }
  return "";
};
