import { ResourceServiceType, fetchAndCollate, getFilterObject } from "../util";
import { restAPI } from "./api";
import {
  TemplatedConfigurationRunRequestType,
  TemplatedConfigurationRunResponseType,
} from "../schemas/TemplatedConfigurationRunSchemas";

type TemplatedConfigurationRunServiceType = Pick<
  ResourceServiceType<TemplatedConfigurationRunResponseType>,
  "createOne" | "getList"
>;

export const TemplatedConfigurationRunAdapter: TemplatedConfigurationRunServiceType =
  {
    createOne: ({
      data: { templated_configuration_name, ...body },
    }: {
      data: TemplatedConfigurationRunRequestType & {
        templated_configuration_name: string;
      };
    }) =>
      restAPI.nodeAPI
        .POST<TemplatedConfigurationRunResponseType>({
          endpoint: `/templated_configurations/${templated_configuration_name}/runs`,
          body,
        })
        .then((resp) => ({ data: resp })),

    getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            endpoint: "/templated_configurations/runs/search",
            body: {
              sort_by: sortBy,
              sort_direction: sortDirection,
              page,
              page_size: pageSize,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),
  };
